import {ICONS} from "patient-ping-remedy/packages/icon";
import React from "react";
import {ReferralDetails} from "../../../../../../api/dto/referral";

export type ReferralStatusType = {
  displayName: string;
  iconClass: ICONS;
  displayMessage: (referral: ReferralDetails) => React.JSX.Element;
}

export type ReferralStatusMap = {
  [key: string]: ReferralStatusType
}

export const ReferralStatuses = {
  Opened: 'Opened',
  Closed: 'Closed',
};

export const AcceptedStatuses = {
  Accepted: 'Accepted',
  Declined: 'Declined',
  Opened: 'Opened',
  Closed: 'Closed',
  // this is not a real OB status we use this for display
  AcceptedElsewhere: 'AcceptedElsewhere',
};

export const ShowStatuses = {
  Show: 'Show',
  'No-Show': 'No-Show',
};

export enum ReportingLogStatus {
  CREATED = "Created",
  FIRST_VIEWED = "First Viewed",
  ACCEPTED = "Accepted",
  ACCEPTED_ELSEWHERE = "Accepted Elsewhere",
  DECLINED = "Declined",
  CLOSED = "Closed",
  SHOW = 'Show',
  NO_SHOW = 'No-Show',
  CONSENT = 'Consent',
  NO_CONSENT = 'No-Consent',
}

export const ReferralStatus : ReferralStatusMap = {
  Unknown: {
    displayName: 'Unknown',
    iconClass: ICONS["trash-alt"],
    displayMessage: () => <>
      <span className="event-text-bold">Unknown</span>
    </>,
  },
  Opened: {
    displayName: 'New referral',
    iconClass: ICONS["user-tag"],
    displayMessage: (referral: ReferralDetails) => <>
      <span className="event-text-bold">Referred </span>
      by {referral?.sent_from_service?.organization.name}
      <span className="pipe-separator">New referral</span>
    </>,
  },
  Closed: {
    displayName: 'Referral closed',
    iconClass: ICONS["user-slash"],
    displayMessage: (referral: ReferralDetails) => <>
      <span className="event-text-bold">Closed </span>
      by {referral?.sent_from_service?.organization.name}
      <span className="pipe-separator">Referral closed </span>
    </>,
  },
  Accepted: {
    displayName: 'Referral accepted',
    iconClass: ICONS["handshake"],
    displayMessage: (referral: ReferralDetails) => <>
      <span className="event-text-bold">Accepted </span>
      by {referral?.sent_to_service?.organization.name}
      <span className="pipe-separator">Referral accepted</span>
    </>,
  },
  AcceptedElsewhere: {
    displayName: 'Accepted elsewhere',
    iconClass: ICONS["hands-helping"],
    displayMessage: (referral) => <></>,
  },
  Declined: {
    displayName: 'Referral declined',
    iconClass: ICONS["handshake-slash"],
    displayMessage: (referral: ReferralDetails) => <>
      <span className="event-text-bold">Declined </span>
      by {referral?.sent_to_service?.organization.name}
      <span className="pipe-separator">Referral declined</span>
    </>,
  },
  'No-Show': {
    displayName: 'Client no showed',
    iconClass: ICONS["user-minus"],
    displayMessage: (referral: ReferralDetails) => <>
      <span className="event-text-bold">Appointment </span>
      at {referral?.sent_to_service?.organization.name}
      <span className="pipe-separator">Client no showed</span>
    </>,
  },
  Show: {
    displayName: 'Client showed',
    iconClass: ICONS["user-check"],
    displayMessage: (referral: ReferralDetails) => <>
      <span className="event-text-bold">Appointment </span>
      at {referral?.sent_to_service?.organization.name}
      <span className="pipe-separator">Client showed</span>
    </>,
  }
};

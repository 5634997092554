import React, {useContext, useEffect, useState} from "react";
import {Client} from "../../../api/dto/dto";
import {useRosterStore} from "../../../store/roster_store";
import {CarecoApiContext} from "../../../app-context/careco-api-context";
import {fontSize} from "patient-ping-remedy/packages/theme";
import ClientsTable from "./ClientsTable";
import {useRosterAwareState} from "../../../hooks/useRosterAwareState";
import {useFilterQuery} from "../../../hooks/useFilterQuery";
import {useInfiniteQuery, useQueryClient} from "@tanstack/react-query";
import {useInView} from "react-intersection-observer";
import Wrapper from '../../common/pages/Wrapper';
import Header from '../../common/pages/Header';
import Loading from "../../common/Loading";
import {useDebounce} from "../../../hooks/useDebounce";
import {getCurrentGroupDetails, getMixpanelEventProperties} from "../../../helpers/mixpanel_helpers";
import {MixpanelClientListEvent, MixpanelEventType} from "../../../api/dto/mixpanel";

const ClientsPage = () => {
  const [clients, setClients] = useState<Client[]>();
  const [searchTotal, setSearchTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useRosterAwareState<string>('');
  const [isMounted, setIsMounted] = useState(false);

  const queryClient = useQueryClient();
  const debouncedSearchTerm = useDebounce(searchTerm);

  const {currentRoster} = useRosterStore();
  const currentRosterId = currentRoster?.id ?? 0;
  const {carecoApi} = useContext(CarecoApiContext);
  const {ref, inView} = useInView({
    rootMargin: '200px',
    skip: !isMounted,
  });

  const {params, queryFnCallback} = useFilterQuery({
    queryKey: (rosterId) => ['clients', rosterId],
    params: {
      assignedTo: [],
    },
  });

  const result = useInfiniteQuery({
    queryKey: ['clients', currentRosterId],
    queryFn: ({pageParam}) => {
      const anyFilterIsActive = queryFnCallback(!!debouncedSearchTerm);

      if (anyFilterIsActive) {
        setSearchTerm('');
      }

      return carecoApi?.getClients(
        currentRosterId,
        pageParam,
        !anyFilterIsActive ? debouncedSearchTerm : '',
        params.assignedTo,
      );
    },
    enabled: !(!carecoApi || !currentRoster),
    getNextPageParam: (lastPage) => lastPage?.nextPage,
    initialPageParam: 1,
  });

  const {
    data: clientsData,
    isLoading,
    isFetching,
    fetchNextPage,
    error,
  } = result;

  useEffect(() => {
    if (clientsData) {
      const fetchedClients = clientsData.pages[clientsData.pages.length - 1]?.data.clients || [];
      setClients((prevClients) => prevClients ? [...prevClients, ...fetchedClients] : fetchedClients);

      const newSearchTotal = clientsData.pages[0]?.data.searchTotal || 0;
      setSearchTotal(newSearchTotal);
    }
  }, [clientsData]);

  useEffect(() => {
    if (inView) {
      if (clientsData?.pages.length) {
        const lastPageSize = clientsData.pages[clientsData.pages.length - 1]?.data.clients.length;
        if (lastPageSize) {
          fetchNextPage();
        }
      }
    }
  }, [inView, clientsData]);

  useEffect(() => {
    // Reset the query cache, otherwise tanstack will refetch X pages instead of only the first page
    invalidate();
  }, [debouncedSearchTerm, currentRosterId]);

  const invalidate = () => {
    queryClient.resetQueries({queryKey: ['clients', currentRosterId]});
    setClients([]);
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const search = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  useEffect( () => {
    if(carecoApi && currentRoster){
      const mixpanelEvent : MixpanelClientListEvent = {
        ...getMixpanelEventProperties(MixpanelEventType.BIH_CLIENT_LIST_VIEWED),
        ...getCurrentGroupDetails(currentRoster),
      };

      carecoApi.postMixpanelEvent(mixpanelEvent);
    }
  }, [carecoApi, currentRoster]);

  return (
    <Wrapper>
      <Header>
        Clients
      </Header>
      <div style={{fontSize: fontSize.standard}}>
        {!currentRoster && <div> No group selected </div>}
        {currentRoster &&
          <>
            <ClientsTable
              clients={clients || []}
              searchTotal={searchTotal}
              loading={isLoading}
              loadError={error}
              searchTerm={searchTerm}
              searchFunction={search}
              query={{params, invalidate}}
            />
            <span ref={ref} onClick={() => fetchNextPage()} />
          </>
        }

        {isFetching && !isLoading && <Loading />}
      </div>
    </Wrapper>
  );
};

export default ClientsPage;

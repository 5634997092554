import {useContext} from 'react';
import {CarecoApiContext} from '../app-context/careco-api-context';
import {
  MixpanelEventType,
  MixpanelSSOLaunchEvent,
  SSOApplication,
  SSOLaunchMethod
} from "../api/dto/mixpanel";
import {getMixpanelEventProperties} from "../helpers/mixpanel_helpers";

export function useOpenBeds() {
  const { carecoApi } = useContext(CarecoApiContext);

  const openOpenBeds = async (applicationName: SSOApplication, launchMethod: SSOLaunchMethod) => {
    const newTab = window.open();
    if (!newTab) {
      return;
    }

    newTab.document.write('Loading...');

    try {
      const htmlContent = await carecoApi?.redirectToOpenBedsSSO();
      if (htmlContent) {
        newTab.document.open();
        newTab.document.write(htmlContent);
        newTab.document.close();

        const event : MixpanelSSOLaunchEvent = {
          ...getMixpanelEventProperties(MixpanelEventType.BIH_SSO_LAUNCHED),
          applicationName,
          launchMethod
        };
        carecoApi?.postMixpanelEvent(event);
      }
    } catch (error) {
      console.error('Error fetching OpenBeds SSO Redirect:', error);
      newTab.document.open();
      newTab.document.write('Error loading content.');
      newTab.document.close();
    }
  };

  return {
    openOpenBeds,
  };
}

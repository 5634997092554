import React, {useContext, useEffect} from "react";
import {
  dayOfWeekFormInfo,
  NotificationInfoMap,
  NotificationFormInfoType,
  NotificationEventSettingType,
  NotificationFormInfo,
  NotificationSchedule,
  NotificationScheduleDays,
  NotificationSettingsDto
} from "../../../../api/dto/notification";
import Checkbox from "patient-ping-remedy/packages/checkbox";
import {
  BannerWrapper,
  EventCategoryWrapper, SaveButtonWrapper, CheckboxWrapper,
  CheckBoxRow, GrayedOutCheckbox, AlertMethodWrapper
} from "./NotificationSettingDetails.styles";
import {createFormFieldConfig, useForm} from "../../../../hooks/useForm";
import {
  LabelTypography,
  SmallSemiBoldTypography,
  SmallTypography,
  StandardTypography
} from "patient-ping-remedy/packages/typography";
import Button from "patient-ping-remedy/packages/button";
import NotificationUserInfo from "./NotificationUserInfo";
import Switch from "patient-ping-remedy/packages/switch";
import {NotificationEnableContainer} from "./NotificationSettingDetails.styles";
import {NotificationScheduleComponent} from "./NotificationScheduleComponent";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {ToastType} from "patient-ping-remedy/packages/toast";
import {useAlertStore} from "../../../../store/alert_store";
import {CarecoApiContext} from "../../../../app-context/careco-api-context";
import Banner, {BannerTypes} from "patient-ping-remedy/packages/banner";
import Spacer from "patient-ping-remedy/packages/spacer";
import {colors, sizes} from "patient-ping-remedy/packages/theme";
import {GENERICERROR} from "../../clients/manual_encounter/constants/BannerMessages";
import InfoIconTooltip from "../../../common/InfoIconTooltip";
import {AxiosError} from "axios";
import Helpers from "../../../../helpers/helpers";
import {MixpanelEventType, MixpanelNotificationsUpdatedEvent} from "../../../../api/dto/mixpanel";
import {getMixpanelEventProperties} from "../../../../helpers/mixpanel_helpers";

function createBooleanFormFieldConfig(key: string[], baseObject: any) {
  return key.reduce((acc, key) => ({
    ...acc,
    ...createFormFieldConfig(
      key,
      baseObject[key],
      { valid: true, message: '' },
      null,
      true
    )
  }), {});
}

const inAppInfoText =
  (<p>In-app alerts are always delivered in real time and <br/>
    cannot be turned off if the event type is enabled.</p>);

type Props = {
  eventSetting: NotificationEventSettingType;
  eventFormInfo: NotificationFormInfoType
  notificationSchedule: NotificationSchedule;
  handleClose: Function;
}

const NotificationSettingDetails = (props: Props) => {
  const queryClient = useQueryClient();
  const { addAlert } = useAlertStore();
  const { carecoApi } = useContext(CarecoApiContext);
  const { eventSetting,eventFormInfo , notificationSchedule } = props;

  const subEventEntries = Object.entries(eventFormInfo);
  const chunkedSubEventEntries = subEventEntries.reduce<Array<[string, NotificationFormInfo]>[]>((acc, cur, index) => {
    if (index % 2 === 0) acc.push([cur]);
    else acc[acc.length - 1].push(cur);
    return acc;
  }, []);

  const eventSettingConfig = createBooleanFormFieldConfig(
    Object.keys(eventFormInfo), eventSetting
  );

  const dayOfWeeksConfig = createBooleanFormFieldConfig(
    Object.keys(dayOfWeekFormInfo), notificationSchedule?.notificationScheduleDays
  );

  const notificationSettingMutation = useMutation({
    mutationFn: async (notificationSettingsRequest: NotificationSettingsDto) => {
      return carecoApi?.putNotificationSetting(notificationSettingsRequest);
    },
    onSuccess: () => {
      addAlert({content: 'Notification settings updated successfully.', type: ToastType.SUCCESS});
      queryClient.invalidateQueries({queryKey: ['notificationSettings']}).then(r => console.log(r));

      setSubmitted(true);
      props.handleClose();
    },
    onError: (error: AxiosError) => {
      const traceId = error.response?.headers['x-trace-id'];
      addAlert({content: `Failed to update notification settings. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
        type: ToastType.ERROR
      });
      console.error(error);
    }
  });

  const {
    isFormChanged,
    setSubmitted,
    form,
    setInitialForm,
    handleChange,
    handleSubmit
  } = useForm(onSave);

  async function onSave() {
    let notificationScheduleDays: string[] = [];
    Object.keys(dayOfWeekFormInfo).forEach((key) => {
      const dayOfWeekKey = key as keyof NotificationScheduleDays;

      if (form[dayOfWeekKey].value) {
        notificationScheduleDays.push(
          dayOfWeekFormInfo[dayOfWeekKey]?.code || ''
        );
      }
    });

    const notificationCategories: Record<string, boolean> = {};
    const notificationCategoriesLabels: string[] = [];

    Object.entries(eventFormInfo).forEach(([key, info]) => {
      const notificationKey = key as keyof typeof eventFormInfo;
      notificationCategories[eventFormInfo[notificationKey]?.code || ''] = form[notificationKey].value;
      if (form[notificationKey].value) {
        notificationCategoriesLabels.push(info.label);
      }
    });

    let json: NotificationSettingsDto = {
      notificationEventSettingDtos: {
        [eventSetting.eventType as string]: {
          enabled: form.enabled.value,
          eventType: eventSetting.eventType,
          emailEnabled: form.email.value,
          smsEnabled: form.sms.value,
          notificationEventSubtypes: notificationCategories
        }
      },
      notificationScheduleDto: {
        fromTime: form.from_time.value.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'}),
        toTime: form.to_time.value.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'}),
        notificationFrequency: form.notification_frequency.value,
        customScheduledEnabled: form.custom_schedule.value,
        timezone: 'US/Eastern',
        notificationScheduleDays: notificationScheduleDays
      }
    };

    notificationSettingMutation.mutate(json);
    props.handleClose();

    const event: MixpanelNotificationsUpdatedEvent = {
      ...getMixpanelEventProperties(MixpanelEventType.BIH_NOTIFICATIONS_UPDATED),
      notificationType: NotificationInfoMap[eventSetting.eventType].profileLabelText,
      notificationEnabled: form.enabled.value,
      eventsEnabled: notificationCategoriesLabels,
      notificationSchedule: form.custom_schedule.value ? 'Custom' : 'Default',
      deliveryMethod: [
        ...form.email.value ? ['Email'] : [],
        ...form.sms.value ? ['SMS'] : [],
        'In-App',
      ],
    };
    carecoApi?.postMixpanelEvent(event);
  }

  useEffect(() => {
    if (!eventSetting || !notificationSchedule ) return;

    setInitialForm({
      ...eventSettingConfig,
      ...dayOfWeeksConfig,
      ...createFormFieldConfig(
        'enabled',
        eventSetting.enabled,
        {valid: true, message: ''},
        null,
        true
      ),
      ...createFormFieldConfig(
        'from_time',
        notificationSchedule?.fromTime,
        {valid: true, message: ''},
        null,
        true
      ),
      ...createFormFieldConfig(
        'to_time',
        notificationSchedule?.toTime,
        {valid: true, message: ''},
        null,
        true
      ),
      ...createFormFieldConfig(
        'email',
        eventSetting?.emailEnabled,
        {valid: true, message: ''},
        null,
        true
      ),
      ...createFormFieldConfig(
        'sms',
        eventSetting?.smsEnabled,
        {valid: true, message: ''},
        null,
        true
      ),

      ...createFormFieldConfig(
        'notification_frequency',
        notificationSchedule?.notificationFrequency,
        {valid: true, message: ''},
        null,
        true
      ),
      ...createFormFieldConfig(
        'custom_schedule',
        notificationSchedule?.customScheduledEnabled,
        {valid: true, message: ''},
        null,
        true

      ),
    });
  }, [eventSetting, notificationSchedule, setInitialForm]);

  const isAllCategoriesSelected = (): boolean => {
    return Object.keys(eventFormInfo).every((key) => {
      return form[key]?.value;
    });
  };

  const isAllCategoriesNotSelected = (): boolean => {
    return Object.keys(eventFormInfo).every((key) => {
      return !form[key]?.value;
    });
  };

  const updateAllCategories = (select: boolean) => {
    Object.keys(eventFormInfo).forEach((key) => {
      handleCategoryChange(key, select);
    });

    if(!select) {
      handleChange(null, 'email', false);
      handleChange(null, 'sms', false);
    }
  };

  const handleCategoryChange = (key: string, value: boolean) => {
    handleChange(null, key, value);
    if (isAllCategoriesNotSelected()) {
      handleChange(null, 'enabled', false);
    }
  };

  const handleEmailSmsChange = (type : string, value : boolean) => {
    handleChange(null, type, value);
    if (form.email.value === false && form.sms.value === false) {
      handleChange(null, 'custom_schedule', false);
    }
  };

  const checkFromTimeBeforeToTime = (): boolean  => {
    if (!form.from_time?.value || !form.to_time?.value)
      return false;

    return form.from_time?.value < form.to_time?.value;
  };

  if (!eventSetting || !notificationSchedule )
    return null;

  return (
    <>
      <NotificationEnableContainer>
        <StandardTypography>Notifications</StandardTypography>
        <Switch
          key={'enabled'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e, 'enabled', e.target.checked)}
          label={'Enabled'}
          disabled={false}
          checked={form.enabled?.value}/>
      </NotificationEnableContainer>

      <BannerWrapper>
        <Banner className={'notification-banner'} type={BannerTypes.INFORMATIONAL}>
          Alerts on client events will be sent based on the selections below and the contact details listed in your user profile.
        </Banner>
      </BannerWrapper>

      <NotificationUserInfo/>

      <EventCategoryWrapper>
        <SmallTypography>Receive alerts</SmallTypography>
        {
          <SmallSemiBoldTypography
            color={colors.blue4}
            onClick={() => updateAllCategories(!isAllCategoriesSelected())}
          >
            {isAllCategoriesSelected() ? 'Deselect all' : 'Select all'}
          </SmallSemiBoldTypography>
        }
      </EventCategoryWrapper>

      {
        chunkedSubEventEntries.map((group, index) => (
          <CheckBoxRow key={index}>
            {
              group.map(([notificationKey, value]) => (
                <CheckboxWrapper selected={form[notificationKey]?.value}>
                  <Checkbox
                    key={notificationKey}
                    value={form[notificationKey]?.value}
                    label={value.label}
                    onChange={(e) => {
                      handleCategoryChange(notificationKey, e);
                    }}
                  />
                </CheckboxWrapper>
              ))
            }
          </CheckBoxRow>
        ))
      }

      <AlertMethodWrapper>
        <SmallTypography>Alert method</SmallTypography>
      </AlertMethodWrapper>

      <CheckBoxRow>
        <GrayedOutCheckbox>
          <Checkbox
            value={true}
            disabled={true}
          />
          <Spacer itemWidth={sizes.xSmall}/>
          <LabelTypography>
            In-app
          </LabelTypography>
          <InfoIconTooltip
            html={inAppInfoText}
            style={{marginLeft: '0.2rem'}}
            data-testId="in-app-tooltip"
            color={colors.gray3}
          />
        </GrayedOutCheckbox>
        <CheckboxWrapper selected={form.email?.value}>
          <Checkbox
            value={form.email?.value}
            label={'Email'}
            onChange={(e) => {
              handleEmailSmsChange('email', e);
            }}
          />
        </CheckboxWrapper>
      </CheckBoxRow>
      <CheckBoxRow>
        <CheckboxWrapper selected={form.sms?.value}>
          <Checkbox
            value={form.sms?.value}
            label={'SMS'}
            onChange={(e) => {
              handleChange(null, 'sms', e);
            }}
          />
        </CheckboxWrapper>
      </CheckBoxRow>

      <NotificationScheduleComponent
        notificationSchedule={notificationSchedule}
        handleChange={handleChange}
        form={form}
      />

      <SaveButtonWrapper>
        <Button
          key={'saveButton'}
          onClick={ (e) => handleSubmit(e) }
          disabled={ !isFormChanged() || !checkFromTimeBeforeToTime()} >
          Save
        </Button>
      </SaveButtonWrapper>
    </>
  );
};

export default NotificationSettingDetails;

import React, {useCallback, useContext, useEffect, useState} from 'react';
import Modal from "patient-ping-remedy/packages/modal";
import {css} from "@emotion/css";
import Button, {StyleType} from "patient-ping-remedy/packages/button";
import CreateUserModalContent from "./CreateUserModalContent";
import {UserRequest, RosterSelection} from "../../../api/dto/dto";
import {CarecoApiContext} from "../../../app-context/careco-api-context";
import {ToastType} from "patient-ping-remedy/packages/toast";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {createFormFieldConfig, useForm} from "../../../hooks/useForm";
import Validate from "../../../helpers/validate";
import ConfirmModal from "../../common/ConfirmModal";
import Helpers from "../../../helpers/helpers";
import {RosterSelectionState} from "./RosterSelection";
import {useAuthStore} from "../../../store/auth_store";
import {Value} from "patient-ping-remedy/packages/dropdown-list/lib/DropdownList";
import RosterHelpers from "../../../helpers/roster_helpers";
import {GENERICERROR, USER_ALREADY_EXISTS} from "../clients/manual_encounter/constants/BannerMessages";
import {AxiosError} from "axios";
import {MixpanelEventType, MixpanelUserInvitedEvent} from "../../../api/dto/mixpanel";
import {getMixpanelEventProperties} from "../../../helpers/mixpanel_helpers";

interface CreateUserModalProps {
  isOpen: boolean;
  hideCreateUserModal: () => void;
  alertFn: Function;
}

const CreateUserModal = (props: CreateUserModalProps) =>  {
  const { carecoApi } = useContext(CarecoApiContext);
  const queryClient = useQueryClient();
  const { currentUser } = useAuthStore();

  const { isValid, isFormChanged, submitted, form, setInitialForm, handleChange } = useForm(handleInviteClick);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { data: rosters, isLoading } = useQuery({
    queryKey: ['rosters'],
    queryFn: () => carecoApi?.getRosters(),
    enabled: !!carecoApi
  });

  const [availableRosterOptions, setAvailableRosterOptions] = useState<Value[]>([]);

  const createUserMutation = useMutation({
    mutationFn: async (user: UserRequest) => carecoApi?.postCreateUser(user),
    onSuccess: (response) => {
      props.alertFn({content: 'User created successfully.', type: ToastType.SUCCESS});
      queryClient.invalidateQueries({queryKey: ['users']}).then(r => console.log(r));
      props.hideCreateUserModal();

      const mixpanelEvent : MixpanelUserInvitedEvent = {
        ...getMixpanelEventProperties(MixpanelEventType.BIH_USER_INVITED),
        sender: currentUser?.id!,
        invitee: response?.id!,
        inviteeGroupIds: form.rosters.value.map((roster: RosterSelectionState) => parseInt(roster.roster.value))
      };
      carecoApi?.postMixpanelEvent(mixpanelEvent);
    },

    onError: (error: AxiosError) => {
      const traceId = error.response?.headers['x-trace-id'];
      if(error?.response?.status === 409){
        props.alertFn({content: `Failed to create user. ${USER_ALREADY_EXISTS} ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR});
      }
      else {
        props.alertFn({
          content: `Failed to create user. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR
        });
      }
      console.error(error);
    }
  });

  async function handleInviteClick() {
    const rosters: RosterSelection[] = [];

    form.rosters.value.forEach((roster: RosterSelectionState) => {
      rosters.push({
        roster_id: parseInt(roster.roster.value),
        data_role_id: parseInt(roster.dataRole.value),
        feature_role_id: parseInt(roster.featureRole.value),
      });
    });

    const createUserRequest : UserRequest = {
      email: form.email.value,
      // phone we will strip all non digits; limit to 10 char
      phone: form.phone.value.replace(/\D/g, '').substring(0, 10),
      first_name: form.first_name.value,
      last_name: form.last_name.value,
      title: form?.title?.value,
      rosters: rosters,
      enabled: true,
    };

    createUserMutation.mutate(createUserRequest);
  }

  const determineAvailableRosterOptions = useCallback(() => {
    // still want to memoize this expensive function
    return RosterHelpers.determineAvailableRosterOptions(form, rosters, currentUser);
  }, [currentUser, form, rosters]);

  useEffect(() => {
    if(!rosters || !currentUser) return;

    let initialRosterOptions = determineAvailableRosterOptions();

    setInitialForm({
      ...createFormFieldConfig(
        'first_name',
        '',
        { valid: false, message: '' },
        [Validate.required, Validate.firstName],
        true
      ),
      ...createFormFieldConfig(
        'last_name',
        '',
        { valid: false, message: '' },
        [Validate.required, Validate.lastName],
        true
      ),
      ...createFormFieldConfig(
        'email',
        '',
        { valid: false, message: '' },
        [Validate.required, Validate.email],
        true
      ),
      ...createFormFieldConfig(
        'phone',
        '',
        { valid: false, message: '' },
        [Validate.required, Validate.phone],
        true
      ),
      ...createFormFieldConfig(
        'rosters',
        [{
          roster: initialRosterOptions[0],
          dataRole: rosters?.dataRoleOptions[0],
          featureRole: rosters?.featureRoleOptions[0]
        }],
        { valid: true, message: '' },
        [Validate.validateRostersUsers],
        true
      ),
    });
  }, [currentUser, rosters, setInitialForm]);

  useEffect(() => {
    if(currentUser && rosters && form.rosters?.value) {
      setAvailableRosterOptions(determineAvailableRosterOptions());
    }
  }, [currentUser, rosters, rosters?.rosterOptions, form.rosters?.value]);

  const handleHideModal = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    if (e.target instanceof HTMLDivElement || e.target instanceof HTMLSpanElement) {
      return;
    }

    if(isFormChanged() && !submitted) {
      setShowConfirmModal(true);
    } else {
      props.hideCreateUserModal();
    }
  };

  return (
    <>
      <Modal
        id={"create-user-modal"}
        headerText={"Invite New Users"}
        isOpen={props.isOpen}
        buttons={[
          <Button key={'cancelButton'} onClick={handleHideModal} styleType={StyleType.TERTIARY}>Cancel</Button>,
          <Button key={'agreeButton'} onClick={handleInviteClick}
            disabled={!isValid() || createUserMutation.status === 'pending'}>Invite</Button>
        ]}
        hideModal={handleHideModal}
        className={css({ width: '40%'})}
      >
        {!isLoading && !Helpers.isEmptyObject(form) && rosters &&
          <CreateUserModalContent
            rosters={rosters}
            availableRosterOptions={availableRosterOptions ?? []}
            form={form}
            handleChange={handleChange}
          />
        }
      </Modal>
      <ConfirmModal show={showConfirmModal} leaveFunction={props.hideCreateUserModal} showFunction={setShowConfirmModal} />
    </>
  );
};

export default CreateUserModal;

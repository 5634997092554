import React, {useContext} from 'react';
import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {ToastType} from 'patient-ping-remedy/packages/toast';
import Switch from 'patient-ping-remedy/packages/switch';
import {GENERICERROR} from '../clients/manual_encounter/constants/BannerMessages';
import {User} from '../../../api/dto/dto';
import {queryClient} from '../../../App';
import {CarecoApiContext} from '../../../app-context/careco-api-context';
import {useAuthStore} from '../../../store/auth_store';
import {useAlertStore} from '../../../store/alert_store';
import {MixpanelEventType, MixpanelNotificationsForAllClientsEvent} from '../../../api/dto/mixpanel';
import {getMixpanelEventProperties} from '../../../helpers/mixpanel_helpers';
import PermissionsHelpers from '../../../helpers/permissions_helpers';
import Helpers from '../../../helpers/helpers';

type Props = {
  currentFetchedUser: User
};

export default function NotificationOnlyAssignedClientSwitch(props: Props) {
  const { currentFetchedUser } = props;

  const { carecoApi } = useContext(CarecoApiContext);
  const { setCurrentUser } = useAuthStore();
  const { addAlert } = useAlertStore();

  const isBambooAdmin = PermissionsHelpers.isBamboo(currentFetchedUser);

  const { mutate, isPending } = useMutation({
    mutationFn(value: boolean) {
      return carecoApi!.patchUser(currentFetchedUser.id, { only_assigned_client_notifications: value });
    },
    onSuccess(user) {
      currentFetchedUser.only_assigned_client_notifications = user.only_assigned_client_notifications;

      setCurrentUser(user);
      queryClient.invalidateQueries({ queryKey: ['currentUser'] }).then(r => console.log(r));

      const event: MixpanelNotificationsForAllClientsEvent = {
        ...getMixpanelEventProperties(MixpanelEventType.BIH_NOTIFICATIONS_FOR_ALL_CLIENTS),
        notificationsForAllClients: !user.only_assigned_client_notifications,
      };
      carecoApi?.postMixpanelEvent(event);

      addAlert({
        content: 'Notification settings updated successfully.',
        type: ToastType.SUCCESS,
      });
    },
    onError(error: AxiosError) {
      console.error(error);
      currentFetchedUser.only_assigned_client_notifications = !currentFetchedUser.only_assigned_client_notifications;

      const traceId = error.response?.headers['x-trace-id'];
      addAlert({
        content: `Failed to update notification settings. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
        type: ToastType.ERROR,
      });
    }
  });

  const handleChange = () => {
    currentFetchedUser.only_assigned_client_notifications = !currentFetchedUser.only_assigned_client_notifications;
    mutate(currentFetchedUser.only_assigned_client_notifications);
  };

  return (
    <Switch
      onChange={handleChange}
      label={'Turn on clients not assigned to me'}
      checked={!currentFetchedUser.only_assigned_client_notifications}
      disabled={isBambooAdmin || isPending}
    />
  );
}

import styled from '@emotion/styled';
import {colors} from "patient-ping-remedy/packages/theme";
import TableAccordion from "patient-ping-remedy/packages/table-accordion";

const NoAccess = styled('div')`
  background-color: ${colors.gray1};
  color: ${colors.gray4};
  padding: 0;
  margin: 0;
`;

const StyledTableAccordion = styled(TableAccordion)`
  &.access-row {
    border-bottom: none;
    margin: 0;
    background-color: transparent;
    
    div:nth-of-type(2) {
      padding: 0;
    }

    &:first-child {
      border-top: none;
      padding-top: 0;
    }
  }
`;

export { NoAccess, StyledTableAccordion };

import React, {JSX, useEffect, useMemo} from "react";
import {
  DataPermission,
  DataRole,
  FeaturePermission,
  FeatureRole,
  Rosters,
  Roster, User
} from "../../../api/dto/dto";
import Banner, {BannerTypes} from "patient-ping-remedy/packages/banner";
import {css} from "@emotion/css";
import Spacer from "patient-ping-remedy/packages/spacer";
import {NoAccess, StyledTableAccordion} from "./AccessDetails.styles";
import DisplayHelpers from "../../../helpers/display_helpers";
import {SmallTypography} from "patient-ping-remedy/packages/typography";
import PermissionsHelpers from "../../../helpers/permissions_helpers";
import { Link } from "react-router-dom";
import { useAuthStore } from "store/auth_store";
import Table from "../Table";
import Display_helpers from "../../../helpers/display_helpers";
import {Value} from "patient-ping-remedy/packages/dropdown-list/lib/DropdownList";
import {dataSourceValues, featureValues} from "../../../helpers/roster_helpers";
import AdminDetails from "../../pages/profile/AdminDetails";

type Props = {
  user: User;
  rosters: Rosters;
  showAdminLink: boolean;
  profilePage?: Boolean;
}

type RosterObject = {
  roster: Roster,
  data_role: DataRole,
  feature_role: FeatureRole
}

type TableDataElement = {
  roster: Roster,
  dataRole: DataRole,
  featureRole: FeatureRole,
  dataRoleData: (string| JSX.Element)[][],
  featureRoleData: (string| JSX.Element)[][]
}

const AccessDetails = (props: Props) => {
  const [tableData, setTableData] = React.useState<TableDataElement[]>([]);
  const { currentUser } = useAuthStore();
  const dataRoleHeaders :Value[] = useMemo(() => dataSourceValues, []);
  const featureRoleHeaders =useMemo(() => featureValues, []);
  function getDataPermissions(rosterObject: RosterObject, permissionType: string) {
    return rosterObject.data_role?.permissions.find((permission: DataPermission) => {
      return permission.data_source === permissionType;
    });
  }

  function getFeaturePermissions(rosterObject: RosterObject, permissionType: string) {
    return rosterObject.feature_role?.permissions.find((permission: FeaturePermission) => {
      return permission.feature === permissionType;
    });
  }

  function getDisplayPermission(permission: DataPermission | FeaturePermission | undefined) {
    let doNotHumanize = ['SSO'];
    if(permission?.level){
      return <span>
        { doNotHumanize.includes(permission.level)
          ? permission.level
          : DisplayHelpers.humanize(permission?.level) }
      </span>;
    } else {
      return <NoAccess>No</NoAccess>;
    }
  }

  useEffect(() => {
    if(props.rosters && props.user) {
      let tableData = props.user?.rosters.map((rosterObject: RosterObject) => {
        let roster = props.rosters?.data.find(roster => roster?.id === rosterObject.roster?.id);

        let dataRoleData = dataRoleHeaders.map((header: Value) => {
          return [header.label, getDisplayPermission(getDataPermissions(rosterObject, header.value))];
        });

        let featureRoleData = featureRoleHeaders.map((header: Value) => {
          return [header.label, getDisplayPermission(getFeaturePermissions(rosterObject, header.value))];
        });

        return {
          roster: roster!,
          dataRole: rosterObject.data_role,
          featureRole: rosterObject.feature_role,
          dataRoleData: dataRoleData,
          featureRoleData: featureRoleData
        };
      });

      setTableData(tableData);
    }
  }, [props, featureRoleHeaders, dataRoleHeaders]);

  const getDisplayBanner = ()=> {
    if(!currentUser) {
      return;
    }

    const isSelf = currentUser?.id === props.user?.id;
    if(isSelf && PermissionsHelpers.isAdmin(props.user)){
      return props.profilePage ? <AdminDetails/> : (
        <Banner type={BannerTypes.INFORMATIONAL} className={css({marginBottom: '30px'})}>
          {DisplayHelpers.contactAdminAccessMessage()}
        </Banner>
      );
    }

    if(PermissionsHelpers.isBamboo(props.user)) {
      if(isSelf) {
        return (
          <Banner type={BannerTypes.INFORMATIONAL} className={css({marginBottom: '30px'})}>
            You are a Bamboo Admin. You have access to all features, data, and groups.
          </Banner> 
        );
      } else {
        return (
          <Banner type={BannerTypes.INFORMATIONAL} className={css({marginBottom: '30px'})}>
            This user is a Bamboo Admin. They have access to all features, data, and groups.
          </Banner>
        );
      }
    }

    if(!props.showAdminLink) {
      return (
        <Banner type={BannerTypes.INFORMATIONAL} className={css({marginBottom: '30px'})}>
          {Display_helpers.contactAccountManagerMessage()}
        </Banner>
      );
    }

    if(props.showAdminLink) {
      if(PermissionsHelpers.isAdmin(props.user)) {
        return props.profilePage ? <AdminDetails/> : (
          <Banner type={BannerTypes.INFORMATIONAL} className={css({marginBottom: '30px'})}>
            <Link to={'/admin'}>Update your access in Admin here.</Link>
          </Banner>
        );
      } else if (PermissionsHelpers.isSuperAdmin(props.user)) {
        return (
          <Banner type={BannerTypes.INFORMATIONAL} className={css({marginBottom: '30px'})}>
            {DisplayHelpers.contactAccountManagerMessage()}
          </Banner>
        );
      }
      else {
        return props.profilePage ? <AdminDetails/> : (
          <Banner type={BannerTypes.INFORMATIONAL} className={css({marginBottom: '30px'})}>
            {DisplayHelpers.contactAdminMessage()}
          </Banner>
        );
      }
    }
  };

  return (
    <>
      {getDisplayBanner()}
      { tableData.map((element: TableDataElement) => {
        return (
          <StyledTableAccordion
            key={`${element.roster?.id}-access-details`}
            className={'access-row'}
            headerItems={[(<h2>{ element?.roster?.name }</h2>)]}>
            {
              PermissionsHelpers.isSuperAdmin(props.user) &&
              <SmallTypography>Super Admins have access to all features and data inside this group.</SmallTypography>
            }
            {element.dataRole &&
              <>
                <Spacer itemDisplay="block" itemHeight="30px" />
                <Table
                  header={['Data Role', DisplayHelpers.humanize(element.dataRole?.name)]}
                  data={element.dataRoleData}
                />
              </>
            }
            {element.featureRole &&
              <>
                <Spacer itemDisplay="block" itemHeight="30px" />
                <Table
                  header={['Feature Role', DisplayHelpers.humanize(element.featureRole?.name)]}
                  data={element.featureRoleData}
                />
              </>
            }
            <Spacer itemDisplay="block" itemHeight="10px" />
          </StyledTableAccordion>
        );
      })}
    </>
  );
};

export default AccessDetails;

import React, {useCallback, useContext, useEffect, useState} from "react";
import SlideInPane from "patient-ping-remedy/packages/slide-in-pane";
import {RosterResponse, RosterSelection, User, UserRequest, UserRoster} from "../../../../api/dto/dto";
import {createFormFieldConfig, useForm} from "../../../../hooks/useForm";
import {CarecoApiContext} from "../../../../app-context/careco-api-context";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import Validate from "../../../../helpers/validate";
import {ToastType} from "patient-ping-remedy/packages/toast";
import ConfirmModal from "../../../common/ConfirmModal";
import {useAlertStore} from "../../../../store/alert_store";
import {Value} from "patient-ping-remedy/packages/dropdown-list/lib/DropdownList";
import Button from "patient-ping-remedy/packages/button";
import Helpers from "../../../../helpers/helpers";
import {RosterSelectionState} from "../RosterSelection";
import PermissionsHelpers from "../../../../helpers/permissions_helpers";
import {useAuthStore} from "../../../../store/auth_store";
import RosterHelpers from "../../../../helpers/roster_helpers";
import Banner, { BannerTypes } from "patient-ping-remedy/packages/banner";
import DisplayHelpers from "helpers/display_helpers";
import StatusIndicator from "./StatusIndicator";
import UserTabsComponent from "components/common/user_details/UserDetailsTabs";
import {GENERICERROR} from "../../clients/manual_encounter/constants/BannerMessages";
import {AxiosError} from "axios";

type Props = {
  user: User;
  rosters: RosterResponse;
  close: Function;
  isOpen: boolean;
  afterSave: Function;
  isLoading: boolean;
  loadError: Error | null;
}

type UpdateUserVariables = {
  userId: string,
  user: UserRequest
}

const UserDetailsDrawer = (props: Props) => {
  const { carecoApi } = useContext(CarecoApiContext);
  const { addAlert } = useAlertStore();
  const { currentUser } = useAuthStore();
  const queryClient = useQueryClient();

  const [availableRosterOptions, setAvailableRosterOptions] = useState<Value[]>([]);

  const {
    isValid, isFormChanged, submitted, setSubmitted,
    form, setInitialForm, handleChange, handleSubmit, resetForm } = useForm(onSubmit);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const determineAvailableRosterOptions = useCallback(() => {
    // still want to memoize this expensive function
    return RosterHelpers.determineAvailableRosterOptions(form, props.rosters, currentUser);
  }, [currentUser, form, props.rosters]);


  const updateUserMutation = useMutation({
    mutationFn: async (updateUserVariables: UpdateUserVariables) => {
      return carecoApi?.putUser(
        updateUserVariables.userId, updateUserVariables.user);
    },
    onSuccess: (response) => {
      addAlert({content: 'User updated successfully.', type: ToastType.SUCCESS});
      queryClient.invalidateQueries({queryKey: ['users']}).then(r => console.log(r));
      setSubmitted(true);
      props.afterSave(response);
    },
    onError: (error: AxiosError) => {
      const traceId = error.response?.headers['x-trace-id'];
      addAlert({content: `Failed to update user. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
        type: ToastType.ERROR
      });
      console.error(error);
    }
  });

  useEffect(() => {
    setShowConfirmModal(false);

    if(props.user && currentUser && props.rosters) {
      let formRosters = props.user?.rosters
        .map((roster: UserRoster) => {
          return {
            roster: {
              label: roster.roster.name ?? '',
              value: roster.roster.id.toString() ?? ''
            },
            dataRole: {
              label: roster.data_role?.name ?? '',
              value: roster.data_role?.id.toString() ?? ''
            },
            featureRole: {
              label: roster.feature_role?.name ?? '',
              value: roster.feature_role?.id.toString() ?? ''
            }
          };
        });

      setInitialForm({
        ...createFormFieldConfig(
          'first_name',
          props.user?.first_name,
          Validate.firstName(props.user?.first_name, 'First name', true),
          [Validate.required, Validate.firstName],
          true
        ),
        ...createFormFieldConfig(
          'last_name',
          props.user?.last_name,
          Validate.lastName(props.user?.last_name, 'Last name', true),
          [Validate.required, Validate.lastName],
          true
        ),
        ...createFormFieldConfig(
          'email',
          props.user?.email,
          Validate.email(props.user?.email || '', 'Email', true),
          [Validate.email],
          true
        ),
        ...createFormFieldConfig(
          'rosters',
          formRosters,
          PermissionsHelpers.isSuperAdmin(props.user)
            ? Validate.validateRostersSuperAdmin(formRosters, 'Rosters', true)
            : Validate.validateRostersUsers(formRosters, 'Rosters', true),
          PermissionsHelpers.isSuperAdmin(props.user) ? [Validate.validateRostersSuperAdmin] : [Validate.validateRostersUsers],
          true
        ),
        ...createFormFieldConfig(
          'phone',
          props.user?.phone,
          Validate.phone(props.user?.phone, 'Phone', true),
          [Validate.phone, Validate.required],
          true
        ),
        ...createFormFieldConfig(
          'title',
          props.user?.title,
          { valid: true, message: '' },
          null,
          true
        ),
        ...createFormFieldConfig(
          'enabled',
          props.user?.enabled,
          { valid: true, message: '' },
          null,
          true
        ),
        ...createFormFieldConfig(
          'pdmp_role',
          props.user?.pdmp_role ?? '',
          { valid: true, message: '' },
          null,
          false
        )
      });

      setAvailableRosterOptions(determineAvailableRosterOptions());
    }
  }, [currentUser, props.rosters, props.user, props.rosters?.data,
    props?.rosters?.rosterOptions, setInitialForm]);

  useEffect(() => {
    if(currentUser && props.rosters && form.rosters?.value) {
      setAvailableRosterOptions(determineAvailableRosterOptions());
    }
  }, [currentUser, props.rosters, props.rosters?.rosterOptions, form.rosters?.value]);

  const handleClose = () => {
    if(isFormChanged() && !submitted) {
      setShowConfirmModal(true);
    } else {
      resetForm();
      props.close();
    }
  };

  async function onSubmit() {
    const rosters: RosterSelection[] = [];

    form.rosters.value.forEach((roster: RosterSelectionState) => {
      let dataRoleId = !Helpers.isEmpty(roster.dataRole.value) ? parseInt(roster.dataRole.value) : null;
      let featureRoleId = !Helpers.isEmpty(roster.featureRole.value) ? parseInt(roster.featureRole.value) : null;

      rosters.push({
        roster_id: parseInt(roster.roster.value),
        data_role_id: dataRoleId,
        feature_role_id: featureRoleId,
      });
    });

    let json : UserRequest = {
      first_name: form.first_name.value,
      last_name: form.last_name.value,
      email: form.email.value,
      // phone we will strip all non digits; limit to 10 char
      phone: form.phone.value.replace(/\D/g, '').substring(0, 10),
      title: Helpers.isEmpty(form.title.value) ? null : form.title.value,
      enabled: form.enabled.value,
      pdmp_role: form.pdmp_role.value || null,
      rosters: rosters
    };

    updateUserMutation.mutate({userId: props.user.id, user: json});
  }

  return (
    <>
      { Helpers.isDefined(props.loadError) &&
        <Banner type={BannerTypes.WARNING}>{ DisplayHelpers.displayErrorMessage(props.loadError) }</Banner> }
      <SlideInPane
        onClose={handleClose}
        isOpen={props.isOpen}
        isOverlayOpen={props.isOpen}
        showNav={false}
        headerText={"User Details"}
        headerChildren={props.user && props.user.status && <StatusIndicator status={props.user.status} />}
        buttons={PermissionsHelpers.isBamboo(props.user) || PermissionsHelpers.isSuperAdmin(props.user)
          || !PermissionsHelpers.canForUserFeatureRole(currentUser!,
            Helpers.getAppFeatureRoleDetails().levels.MANAGE,
            Helpers.getAppFeatureRoleDetails().permissions.USER_PERMISSIONS)
          ? []
          : [
            <Button key={'saveButton'}
              onClick={(e : React.FormEvent) => handleSubmit(e)}
              disabled={!isValid() || updateUserMutation.status === "pending" || (currentUser?.id === props.user?.id)}>
               Save
            </Button>
          ]
        }
      >
        { props.loadError && <Banner type={BannerTypes.WARNING}>{ DisplayHelpers.displayErrorMessage(props.loadError) }</Banner> }
        <UserTabsComponent
          availableRosterOptions={availableRosterOptions}
          handleChange={handleChange}
          form={form}
          enableInputsForSelf={false}
          showGroupForm={true}
          showAdminLink={false}
          user={props.user}
          rosters={props.rosters}
          isLoading={props.isLoading}
          loadError={props.loadError}
        />
        <ConfirmModal show={showConfirmModal} leaveFunction={props.close} showFunction={setShowConfirmModal} />
      </SlideInPane>
    </>
  );
};

export default UserDetailsDrawer;

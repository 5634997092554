import {Fragment, useContext, useState} from "react";
import {CarecoApiContext} from "../../../app-context/careco-api-context";
import {useAuthStore} from "../../../store/auth_store";
import {useQuery} from "@tanstack/react-query";
import Banner, {BannerTypes} from "patient-ping-remedy/packages/banner";
import {Link} from "react-router-dom";
import Modal from "patient-ping-remedy/packages/modal";
import Table from "../../common/Table";
import {StyledTableAccordion} from "../../common/user_details/AccessDetails.styles";
import Spacer from "patient-ping-remedy/packages/spacer";
import {css} from "@emotion/css";

const AdminDetails = () => {
  const {carecoApi} = useContext(CarecoApiContext);
  const {currentUser} = useAuthStore();
  const [showModal, setShowModal] = useState(false);

  const { data: adminDetails} = useQuery({
    queryKey: ['admin-details', currentUser?.id],
    queryFn: () => carecoApi?.getAdminDetails(),
    enabled: !!carecoApi
  });

  const headers = ['Role', 'Name', 'Email'];

  const renderTables = adminDetails?.map((admin) => {
    return (
      <StyledTableAccordion
        key={`roster-${admin.rosterId}-access-details`}
        className="access-row"
        headerItems={[
          <h2 key={`roster-header-${admin.rosterId}`}> {admin.rosterName} </h2>
        ]}
      >
        <Table
          key={admin.rosterId}
          data={admin.adminDetails.map((adminData) => ({
            role: adminData.role,
            fullName: adminData.fullName,
            email: (
              <a style={{ textDecoration: 'none' }} href={`mailto:${adminData.email}`}>
                {adminData.email}
              </a>
            ),
          }))}
          header={headers}
        />
      </StyledTableAccordion>
    );
  });

  return (
    <Fragment>
      <Banner type={BannerTypes.INFORMATIONAL}>
        To change your platform functionality,
        <Spacer itemWidth={"0.4rem"} />
        <Link to="#" onClick={() => setShowModal(true)} style={{ textDecoration: 'none' }}>
          contact your admin.
        </Link>
      </Banner>
      {showModal && (
        <Modal
          headerText="Admin Details"
          hideModal={() => setShowModal(false)}
          isOpen={showModal}
          className={css({minWidth: "800px"})}
        >
          {renderTables}
        </Modal>
      )}
    </Fragment>
  );
};

export default AdminDetails;

import {useContext} from 'react';
import {CarecoApiContext} from '../app-context/careco-api-context';
import {MixpanelEventType, MixpanelSSOLaunchEvent, SSOApplication, SSOLaunchMethod} from "../api/dto/mixpanel";
import {getMixpanelEventProperties} from "../helpers/mixpanel_helpers";

export function useFindHelp() {
  const { carecoApi } = useContext(CarecoApiContext);

  const openFindHelp = async (rosterId: number, patientId: string, launchMethod = SSOLaunchMethod.CREATE_BUTTON) => {
    const newTab = window.open();
    if (!newTab) {
      return;
    }

    newTab.document.write('Loading...');

    try {
      const url = await carecoApi?.getFindHelpUrl(rosterId, patientId);
      if (url) {
        newTab.location.href = url;

        const event : MixpanelSSOLaunchEvent = {
          ...getMixpanelEventProperties(MixpanelEventType.BIH_SSO_LAUNCHED),
          applicationName: SSOApplication.FINDHELP,
          launchMethod
        };
        carecoApi?.postMixpanelEvent(event);
      }
    } catch (error) {
      console.error('Error fetching FindHelp SSO Redirect:', error);
      newTab.document.open();
      newTab.document.write('Error loading content.');
      newTab.document.close();
    }
  };

  return {
    openFindHelp,
  };
}

import Header from "../../../../common/pages/Header";
import {
  Container,
  HeaderWrapper,
  LeftColumn,
  LeftColumnContent,
  RightColumn,
  RightColumnContent
} from "./ClientSummaryTab.styles";
import {
  ADTItem,
  ClientEncounter,
  ClientProfile,
  EncounterType,
  EncounterTypes
} from "../../../../../api/dto/client-profile";
import ClientSummaryTimeline from "./ClientSummaryTimeline";
import VisTimeline from "../../../../common/horizontal_timeline/VisTimeline";
import React, {useContext, useMemo, useState} from "react";
import {useAuthStore} from "../../../../../store/auth_store";
import PermissionsHelpers from "../../../../../helpers/permissions_helpers";
import Helpers from "../../../../../helpers/helpers";
import {useRosterStore} from "../../../../../store/roster_store";
import PdmpScoreTile, {PdmpProps} from "../pmp_tab/PdmpScoreTile";
import {GroupTypes} from "./timeline_components/ADTCard.constants";
import {InView} from "react-intersection-observer";
import FilterComponent, {
  DefaultFilterOption
} from "../../../../common/FilterComponent";
import { MixpanelEventType, MixpanelViewFilteredEvent, TabName } from "../../../../../api/dto/mixpanel";
import {getMixpanelEventProperties} from "../../../../../helpers/mixpanel_helpers";
import {CarecoApiContext} from "../../../../../app-context/careco-api-context";

type Props = {
  patient: ClientProfile;
  encounters: ClientEncounter[];
  pdmp?: PdmpProps;
  setInView: (value: boolean) => void;
  isLoading: boolean;
  isPolling: boolean;
  horizontalInView: boolean;
  setHorizontalInView: (value: boolean) => void;
  forceRefresh: Function;
  openDemographics: Function;
}

function cleanedAdtEvents(clientEncounters: ClientEncounter[]): ClientEncounter[] {
  return clientEncounters.filter((encounter) => {
    if (encounter.type === EncounterType.ADT) {
      const adtContent = encounter.content as ADTItem;
      const facilityType = adtContent.admittingFacility?.type ?? 'Unknown';
      return GroupTypes[facilityType] !== undefined;
    }
    return true;
  });
}

const filterOptions: DefaultFilterOption[] = [
  {
    type: 'multi-select-checkbox',
    label: 'EVENTS',
    dataType: 'array',
    options: Object.entries(EncounterTypes).map(([key, value]) => ({
      id: key,
      label: value,
      value: key
    })).sort((a, b) => a.label.localeCompare(b.label)),
    key: 'encounterTypes'
  }
];

const ClientSummaryTab = (props: Props) => {
  const { currentUser } = useAuthStore();
  const { currentRoster } = useRosterStore();
  const {carecoApi} = useContext(CarecoApiContext);
  const hasPdmp = useMemo(() => {
    if(!currentUser || !currentRoster) return false;

    return PermissionsHelpers.canForUserDataRoleRoster(
      currentUser,
      currentRoster,
      Helpers.getAppDataRoleDetails().levels.READ,
      Helpers.getAppDataRoleDetails().permissions.PDMP,
    );
  }, [currentUser, currentRoster]);

  const [currentFilters, setCurrentFilters] = useState<{encounterTypes: string[]}>({
    encounterTypes: []
  });

  const postMixpanelEvent = (updatedFilters: string[]) => {
    const updatedFilterValues = updatedFilters.map(filter => EncounterTypes[filter]);
    const event: MixpanelViewFilteredEvent = {
      ...getMixpanelEventProperties(MixpanelEventType.BIH_VIEW_FILTERED),
      viewName: TabName.CLIENT_PROFILE,
      filterCriteria: updatedFilterValues
    };
    carecoApi?.postMixpanelEvent(event);
  };

  const handleFilterChange = ( key: string, checked: boolean) => {
    let updatedFilters = [...currentFilters.encounterTypes];
    if(checked){
      updatedFilters.push(key);
    }else{
      updatedFilters = updatedFilters.filter(filter => filter !== key);
    }
    postMixpanelEvent(updatedFilters);
    setCurrentFilters({encounterTypes: updatedFilters});
  };

  const cleanedClientEncounters = useMemo(() => {
    return cleanedAdtEvents(props.encounters);
  }, [props.encounters]);

  const filteredEncounters = useMemo(() => {
    const selectedTypes = currentFilters.encounterTypes;
    if (selectedTypes.length === 0) {
      return cleanedClientEncounters;
    } else {
      return cleanedClientEncounters.filter(
        (encounter) => {
          if (encounter.type === EncounterType.ADT) {
            const adtContent = encounter.content as ADTItem;
            const displayName = GroupTypes[adtContent.admittingFacility?.type ?? 'Unknown'].displayName;
            if(displayName === 'Hospital') {
              return selectedTypes.includes('HOSPITAL');
            }else if(displayName === 'Post Acute') {
              return selectedTypes.includes('PAC');
            }
            return false;
          }
          return selectedTypes.includes(encounter.type);
        }
      );
    }
  }, [ currentFilters, cleanedClientEncounters]);

  if (!props.encounters) return null;

  return (
    <>
      <Container singleColumn={hasPdmp ?? false}>
        <LeftColumn>
          <Header margin="3rem">Timeline</Header>
          <LeftColumnContent>
            <VisTimeline encounters={cleanedClientEncounters}
              horizontalInView={props.horizontalInView}
              setHorizontalInView={props.setHorizontalInView}
              isLoading={props.isLoading}
              isPolling={props.isPolling}
            />
            <HeaderWrapper>
              <Header margin="0">Recent Events</Header>
              <FilterComponent
                filterFunction={handleFilterChange}
                filterArgs={{
                  filterOptions: filterOptions,
                  currentFilters,
                }}
              />
            </HeaderWrapper>
            <ClientSummaryTimeline
              patient={props.patient}
              encounters={filteredEncounters}
              isLoading={props.isLoading}
              isPolling={props.isPolling}
              forceRefresh={props.forceRefresh}
              openDemographics={props.openDemographics}
            />
            <InView onChange={(inView) => props.setInView(inView)} children={<span/>}/>
          </LeftColumnContent>
        </LeftColumn>
        <RightColumn>
          { hasPdmp && props.pdmp &&
            <>
              <Header margin="3rem">Controlled substance clarity score</Header>
              <RightColumnContent>
                <PdmpScoreTile  {...props.pdmp}/>
              </RightColumnContent>
            </>
          }
        </RightColumn>
      </Container>
    </>
  );
};

export default ClientSummaryTab;

import {CarecoApiContext} from "../app-context/careco-api-context";
import {useContext} from "react";
import {MixpanelEventType, MixpanelSSOLaunchEvent, SSOApplication, SSOLaunchMethod} from "../api/dto/mixpanel";
import {getMixpanelEventProperties} from "../helpers/mixpanel_helpers";

export function useRoundTrip() {
  const { carecoApi } = useContext(CarecoApiContext);

  const openRoundTrip = async (launchMethod = SSOLaunchMethod.CREATE_BUTTON) => {
    window.open(process.env.REACT_APP_ROUND_TRIP_URL);

    const event : MixpanelSSOLaunchEvent = {
      ...getMixpanelEventProperties(MixpanelEventType.BIH_SSO_LAUNCHED),
      applicationName: SSOApplication.ROUND_TRIP,
      launchMethod
    };
    carecoApi?.postMixpanelEvent(event);
  };

  return {
    openRoundTrip,
  };
}

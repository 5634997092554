import {ICONS} from "patient-ping-remedy/packages/icon";
import Tooltip from "patient-ping-remedy/packages/tool-tip";
import { Wrapper } from "./Part2Disclaimer.styles";
import IconButton from "patient-ping-remedy/packages/icon-button";
import {StyleType} from "patient-ping-remedy/packages/button";
const disclaimerText = "42 CFR Part 2 prohibits the unauthorized use " +
  "or disclosure of Part 2 data which may be contained here.  " +
  "The general patient consent obtained allows for the use, " +
  "disclosure, and redisclosure of such information for any treatment, " +
  "payment, or health care operations purpose under HIPAA.";

type Props = {
  styleType: StyleType;
}

const Part2Disclaimer = (props: Props) => {
  return (
    <Wrapper>
      <Tooltip text={disclaimerText}>
        <IconButton
          data-testid="part2-disclaimer"
          iconClass={ICONS['info-circle']}
          styleType={props.styleType ?? StyleType.PRIMARY}
        />
      </Tooltip>
    </Wrapper>
  );
};

export default Part2Disclaimer;

import styled from '@emotion/styled';
import {colors} from "patient-ping-remedy/packages/theme";
import Input from "patient-ping-remedy/packages/input";

export const FormRow = styled.div`
  display: flex;
`;

export const PdmpRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PdmpInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.6rem;
`;

export const PdmpInput = styled(Input)`
  display: flex;
  align-items: flex-end;
  margin: 0 -40px 0 0;
  height: 4rem;
  width: 100%;
  background-color: ${colors.gray2};
`;

import styled from '@emotion/styled';
import {colors, fontSize} from "patient-ping-remedy/packages/theme";
import Spinner from "patient-ping-remedy/packages/spinner";


export const ScanRosterHeader = styled('div')`
  padding: 8px 0 8px 0;
  font-size: ${fontSize.displaySmall};
  color: ${colors.charcoal};
`;

export const StyledSpinner = styled(Spinner)`
  margin-left: 10px;
  margin-right: 10px;
`;

export const SpinnerHint = styled('span')`
  font-size: ${fontSize.small};
`;

import {NotificationEventType} from "../../../api/dto/notification";
import React from "react";

import {ReactComponent as CrisisIcon} from "../../../assets/icons/Custom-Icon-Crisis.svg";
import {ReactComponent as ReferralIcon} from "../../../assets/icons/Custom-Icon-Referral.svg";
import {ReactComponent as JusticeInvolvedIcon} from "../../../assets/icons/Custom-Icon-JI.svg";
import Icon, {ICONS} from "patient-ping-remedy/packages/icon";
import {colors} from "patient-ping-remedy/packages/theme";

type NotificationIconInfoType = { [K in NotificationEventType]: React.ReactElement };

export const NotificationIcons: NotificationIconInfoType = {
  CRISIS: <CrisisIcon/>,
  REFERRAL: <ReferralIcon/>,
  HOSPITAL: <Icon iconClass={ICONS.hospital} style={{height: 18, width: 18}} color={colors.white}/>,
  JI: <JusticeInvolvedIcon style={{height: 20, width: 20}}/>,
  FIND_HELP: <ReferralIcon/>,
  POST_ACUTE_CARE: <Icon iconClass={ICONS["clinic-medical"]} style={{height: 18, width: 18}} color={colors.white}/>
};

import Popover from "patient-ping-remedy/packages/popover";
import React, {useState} from "react";
import Avatar from "./Avatar";
import {Roster, User} from "../../api/dto/dto";
import PopoverAvatarDetails from "./PopoverAvatarDetails";

type Props = {
  user?: User | null;
  roster?: Roster | null;
}
const AvatarDetails = (props: Props) => {
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);

  return (
    <Popover
      position={"right"}
      toggle={() => setDetailsOpen(!detailsOpen)}
      isOpen={detailsOpen}
      content={<PopoverAvatarDetails user={props.user!} roster={props.roster} />}>
      <div onMouseEnter={() => setDetailsOpen(true)} onMouseLeave={() => setDetailsOpen(false)}>
        <Avatar user={props.user}/>
      </div>
    </Popover>
  );
};

export default AvatarDetails;

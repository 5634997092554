import React from 'react';
import { SmallSemiBoldTypography, SmallTypography } from 'patient-ping-remedy/packages/typography';
import {Roster, User} from '../../api/dto/dto';
import Avatar from 'components/common/Avatar';
import {Wrapper, UserDetailsWrapper} from './PopoverAvatarDetails.styles';

type Props = {
    user: User;
    roster?: Roster | null;
}

const PopoverAvatarDetails = (props: Props) => {
  return (
    <Wrapper>
      <Avatar user={props.user} />
      <UserDetailsWrapper>
        <SmallSemiBoldTypography>{props.user.first_name} {props.user.last_name}</SmallSemiBoldTypography>
        {props.user.title && <SmallTypography>{props.user.title}</SmallTypography>}
        {props.roster && <SmallTypography>{props.roster.name}</SmallTypography>}
        <SmallTypography>{props.user.email}</SmallTypography>
      </UserDetailsWrapper>
    </Wrapper>
  );
};

export default PopoverAvatarDetails;

import React, { useContext, useEffect } from 'react';
import {useAuthStore} from "../../../store/auth_store";
import Wrapper from '../../common/pages/Wrapper';
import Header from '../../common/pages/Header';
import {TopRegion, BorderedContent, Content} from "./ProfilePage.styles";
import { createFormFieldConfig, useForm } from 'hooks/useForm';
import Validate from 'helpers/validate';
import UserTabsComponent from 'components/common/user_details/UserDetailsTabs';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CarecoApiContext } from 'app-context/careco-api-context';
import { UserProfileRequest } from 'api/dto/dto';
import Button from 'patient-ping-remedy/packages/button';
import { ToastType } from 'patient-ping-remedy/packages/toast';
import { useAlertStore } from 'store/alert_store';
import Helpers from 'helpers/helpers';
import Banner, { BannerTypes } from 'patient-ping-remedy/packages/banner';
import DisplayHelpers from 'helpers/display_helpers';
import Loading from 'components/common/Loading';
import { useBlocker } from 'react-router-dom';
import ConfirmModal from 'components/common/ConfirmModal';
import NotificationSettingList from './NotificationSettingList';
import {AxiosError} from "axios";
import {GENERICERROR} from "../clients/manual_encounter/constants/BannerMessages";

type UpdateUserVariables = {
  userId: string,
  user: UserProfileRequest
}

function ProfilePage() {
  const { carecoApi } = useContext(CarecoApiContext);
  const { addAlert } = useAlertStore();
  const { currentUser, setCurrentUser } = useAuthStore();
  const queryClient = useQueryClient();

  const blocker = useBlocker(({ currentLocation, nextLocation }) =>
    isFormChanged() && !submitted && currentLocation.pathname !== nextLocation.pathname
  );

  const updateUserProfileMutation = useMutation({
    mutationFn: async (updateUserVariables: UpdateUserVariables) => {
      return carecoApi?.putUserProfile(updateUserVariables.userId, updateUserVariables.user);
    },
    onSuccess: (response) => {
      addAlert({content: 'User Profile updated successfully.', type: ToastType.SUCCESS});
      queryClient.invalidateQueries({queryKey: ['currentUser']}).then(r => console.log(r));
      queryClient.invalidateQueries({queryKey: ['users']}).then(r => console.log(r));
      setCurrentUser(response?.data);
      setSubmitted(true);
    },
    onError: (error:AxiosError) => {
      const traceId = error.response?.headers['x-trace-id'];
      if(error?.response?.data==='DEA number is invalid'){
        addAlert({content: `Failed to update User Profile. Invalid DEA. ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR
        });
        return;
      }
      else if(error?.response?.data==='NPI number is invalid'){
        addAlert({content: `Failed to update User Profile. Invalid NPI. ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR
        });
        return;
      }else {
        addAlert({content: `Failed to update User Profile. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR
        });
      }
      console.error(error);
    }
  });

  const {
    data: rosters,
    isLoading: isLoadingRoster
  } = useQuery({
    queryKey: ['rosters'],
    queryFn: () => carecoApi?.getRosters(),
    enabled: !!carecoApi
  });

  const { 
    data: currentFetchedUser,
    isLoading: isLoadingUser, 
    error: getUserError
  } = useQuery({
    queryKey: ['currentUser', currentUser?.id],
    queryFn: () => {
      return carecoApi?.getUser(currentUser?.id!);
    },
    enabled: !!currentUser?.id && !!carecoApi
  });

  const {
    data: notificationSettings,
    isLoading: isLoadingNotificationSettings,
    error: getNotificationError
  } = useQuery({
    queryKey: ['notificationSettings'],
    queryFn: () => {
      return carecoApi?.getNotificationSetting();
    },
    enabled: !!carecoApi
  });

  const {
    isValid,
    isFormChanged,
    submitted, setSubmitted,
    form, setInitialForm,
    handleChange,
    handleSubmit
  } = useForm(onSubmit);

  async function onSubmit() {
    let json : UserProfileRequest = {
      first_name: form.first_name.value,
      last_name: form.last_name.value,
      email: form.email.value,
      // phone we will strip all non digits; limit to 10 char
      phone: form.phone.value.replace(/\D/g, '').substring(0, 10),
      title: Helpers.isEmpty(form.title.value) ? null : form.title.value,
      dea_number: Helpers.isEmpty(form.dea_number.value) ? null :form.dea_number.value,
      npi_number:Helpers.isEmpty(form.npi_number.value) ? null :form.npi_number.value,
      sln_value: Helpers.isEmpty(form.sln.value.value) ? null : form.sln.value.value,
      sln_type: Helpers.isEmpty(form.sln.value.type) ? null : form.sln.value.type,
      sln_jurisdiction: Helpers.isEmpty(form.sln.value.jurisdiction) ? null : form.sln.value.jurisdiction,
      pdmp_role: Helpers.isEmpty(form.pdmp_role.value) ? null : form.pdmp_role.value,
    };

    if(currentFetchedUser && json) {
      updateUserProfileMutation.mutate({userId: currentFetchedUser.id, user: json});
    }
  }

  useEffect(() => {
    if(currentFetchedUser && rosters) {
      setInitialForm({
        ...createFormFieldConfig(
          'first_name',
          currentFetchedUser?.first_name,
          Validate.firstName(currentFetchedUser?.first_name, 'First name', true),
          [Validate.required, Validate.firstName],
          true
        ),
        ...createFormFieldConfig(
          'last_name',
          currentFetchedUser?.last_name,
          Validate.lastName(currentFetchedUser?.last_name, 'Last name', true),
          [Validate.required, Validate.lastName],
          true
        ),
        ...createFormFieldConfig(
          'email',
          currentFetchedUser?.email,
          Validate.email(currentFetchedUser?.email || '', 'Email', true),
          [Validate.email],
          true
        ),
        ...createFormFieldConfig(
          'phone',
          currentFetchedUser?.phone,
          Validate.phone(currentFetchedUser?.phone, 'Phone', true),
          [Validate.phone, Validate.required],
          true
        ),
        ...createFormFieldConfig(
          'title',
          currentFetchedUser?.title,
          { valid: true, message: '' },
          null,
          true
        ),
        ...createFormFieldConfig(
          'dea_number',
          currentFetchedUser?.dea_number,
          { valid: true, message: '' },
          null,
          false
        ),
        ...createFormFieldConfig(
          'npi_number',
          currentFetchedUser?.npi_number,
          { valid: true, message: '' },
          null,
          false
        ),
        ...createFormFieldConfig(
          'sln',
          {
            value: currentFetchedUser?.sln_value ?? '',
            type: currentFetchedUser?.sln_type ?? '',
            jurisdiction: currentFetchedUser?.sln_jurisdiction ?? ''
          },
          { valid: true, message: '' },
          [Validate.validateSln],
          false
        ),
        ...createFormFieldConfig(
          'pdmp_role',
          currentFetchedUser?.pdmp_role ?? '',
          { valid: true, message: '' },
          null,
          false
        ),
      });
    }

  }, [currentFetchedUser, rosters, rosters?.data, setInitialForm]);

  const updateDisableSave = () => {
    return (submitted ||
      !isFormChanged() ||
      !isValid() ||
      updateUserProfileMutation.status === 'pending');
  };

  if (isLoadingUser || isLoadingRoster || isLoadingNotificationSettings) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <TopRegion>
        <Header>
          My Profile
        </Header>
        {
          currentFetchedUser && rosters &&
          <Button key={'saveButton'}
            onClick={(e : React.FormEvent) => handleSubmit(e)}
            disabled={updateDisableSave()}>
                Save
          </Button>
        }
      </TopRegion>
      <Content>
        <BorderedContent>
          {currentFetchedUser && rosters &&
          <>
            { Helpers.isDefined(getUserError) &&
              <Banner type={BannerTypes.WARNING}>{ DisplayHelpers.displayErrorMessage(getUserError) }</Banner>
            }
            <UserTabsComponent
              user={currentFetchedUser}
              rosters={rosters}
              isLoading={isLoadingUser}
              loadError={getUserError}
              profilepage={true}
              enableInputsForSelf={true}
              showGroupForm={false}
              showAdminLink={true}
              handleChange={handleChange}
              form={form}
              availableRosterOptions={[]}
            />
            <ConfirmModal
              show={blocker.state === "blocked"}
              leaveFunction={blocker.proceed!}
              showFunction={blocker.reset!}
            />
          </>
          }
        </BorderedContent>

        <BorderedContent>
          { currentFetchedUser && notificationSettings &&
            <NotificationSettingList
              currentFetchedUser={currentFetchedUser}
              notificationSettings={notificationSettings}
              isLoading={isLoadingNotificationSettings}
              loadError={getNotificationError}
            />
          }
        </BorderedContent>
      </Content>


    </Wrapper>
  );
}

export default ProfilePage;

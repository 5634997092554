import React from 'react';
import {ICONS} from 'patient-ping-remedy/packages/icon';
import IconButton from 'patient-ping-remedy/packages/icon-button';

export default function HelpWidget() {
  return (
    <IconButton
      iconClass={ICONS['question-circle']}
      overflowBackground
    />
  );
}

import React, {Fragment, useState} from 'react';
import {ICONS} from 'patient-ping-remedy/packages/icon';
import {StandardTypography} from 'patient-ping-remedy/packages/typography';
import Popover from 'patient-ping-remedy/packages/popover';
import {useAuthStore} from '../../../store/auth_store';
import {Client, Roster} from '../../../api/dto/dto';
import {useClientUserAssignments} from '../../../hooks/useClientUserAssignments';
import Avatar from '../../common/Avatar';
import AssignedToClientList, {AssignUserParams} from './AssignedToClientList';
import {Wrapper} from './AssignedToClient.styles';
import AvatarDetails from "../../common/AvatarDetails";

type Props = {
  client: Client;
  roster?: Roster | null;
  maxCount?: number;
  assignUser?(params: AssignUserParams): Promise<unknown>;
}

export default function AssignedToClient(props: Props) {
  const { maxCount = 2 } = props;
  const { currentUser} = useAuthStore();
  const [assignedListOpen, setAssignedListOpen] = useState<boolean>(false);
  const maxCountWithoutSelf = maxCount - 1;
  const { userAssignmentsWithoutSelf, assignedToSelf} = useClientUserAssignments(props.client);
  const content = (
    <Wrapper>
      {assignedToSelf ?
        <AvatarDetails user={currentUser} roster={props.roster} />
        : <Avatar iconClass={ICONS['user-plus']} />}
      {userAssignmentsWithoutSelf
        .sort((userAssignment) => userAssignment.user.id === currentUser?.id ? -1 : 0)
        .map((userAssignment, index) => (
          <Fragment key={index}>
            {index < maxCountWithoutSelf &&
                <AvatarDetails user={userAssignment.user} roster={props.roster} />
            }
            {index === maxCountWithoutSelf && (
              <StandardTypography>+{userAssignmentsWithoutSelf.length - maxCountWithoutSelf}</StandardTypography>
            )}
          </Fragment>
        ))
      }
    </Wrapper>
  );

  return (
    <Popover
      isOpen={assignedListOpen}
      toggle={() => setAssignedListOpen(!assignedListOpen)}
      position="bottom-start"
      content={
        <AssignedToClientList client={props.client} assignUser={props.assignUser}/>
      }
    >
      {content}
    </Popover>
  );
}

import {
  AdminDetailByRoster,
  AppSettings,
  AuthoritiesResponse,
  BackendVersions,
  ClientGroup,
  Clients,
  LoginResponse,
  Organization,
  RosterPermissions,
  RosterResponse,
  Rosters,
  User,
  UserPatchRequest,
  UserProfileRequest,
  UserRequest,
  WalkMeData
} from "./dto/dto";
import axios from "axios";
import {ClientProfileResponse, Form} from "./dto/client-profile";
import {Value} from "patient-ping-remedy/packages/dropdown-list/lib/DropdownList";
import Helpers from "../helpers/helpers";
import {NotificationResponse, NotificationSettingsDto} from "./dto/notification";
import NotificationHelpers from "../helpers/notification_helpers";
import DisplayHelpers from "../helpers/display_helpers";
import {DischargeDisposition, EncounterClient, Insurance, SearchFacility} from "./dto/encounter";
import {PatientResponse} from "./dto/pdmp";
import {ScanRosterResponse} from "./dto/tools";
import {MixpanelEvent} from "./dto/mixpanel";

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response?.status) {
    window.location.pathname = "/logout";
  } else {
    return Promise.reject(error);
  }
});

export default class CarecoApi {
  getAccessToken: Function;
  baseUrl: string;
  
  constructor(getAccessToken: Function) {
    this.getAccessToken = getAccessToken;
    this.baseUrl = process.env.REACT_APP_CARECO_API_BASE_URL;
  }

  async getAuthorities() {
    let response = await axios.get(this.baseUrl + "/authorities", {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      },
    });

    let data: AuthoritiesResponse = response.data;

    return data;
  }
  async postAppSettings(appSettingsData : AppSettings) {
    return axios.post(this.baseUrl + "/app_settings",
      appSettingsData,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async getAppSettings() {
    let response = await axios.get(this.baseUrl + "/app_settings", {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      },
    });

    let data: AppSettings = response.data;

    return data;
  }

  async getRosters() {
    let response = await axios.get(this.baseUrl + "/rosters", {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      },
    });

    let data: Rosters = response.data;
    let rosterOptions: Value[] = data?.data?.map(roster => {
      return {label: roster.name, value: roster.id.toString()};
    }) ?? [];
    let dataRoleOptions: Value[] = data?.data_roles?.map(role => {
      return {label: role.name, value: role.id.toString()};
    }) ?? [];
    let featureRoleOptions: Value[] = data?.feature_roles?.map(role => {
      return {label: role.name, value: role.id.toString()};
    }) ?? [];
    let allOrganizations: Value[] = data?.data?.map(roster => {
      return {label: roster.organization.name, value: roster.organization.id.toString()};
    }) ?? [];
    let organizationOptions: Value[] = Helpers.removeDuplicates(allOrganizations);

    let rosterResponse: RosterResponse = {
      data,
      rosterOptions,
      dataRoleOptions,
      featureRoleOptions,
      organizationOptions
    };

    return rosterResponse;
  }

  async getClients(rosterId: number, start: number, name?: string | null, userAssignedIds?: string[]) {
    let url = `${this.baseUrl}/rosters/${rosterId}/clients?start=${start}`;

    if (name) {
      url += `&search_name=${name}`;
    }

    if (userAssignedIds?.length) {
      for (const userAssignedId of userAssignedIds) {
        url += `&user_assigned_ids=${userAssignedId}`;
      }
    }

    let response = await axios.get(encodeURI(url), {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      },
    });

    let data: Clients = response.data;

    return {
      id: start,
      rosterId: rosterId,
      data,
      nextPage: start + 1
    };
  }

  async getClientProfile(patientId: string, rosterId : number, viewing: boolean, pageNum: number, forceRefresh: boolean = false) {
    let url = `${this.baseUrl}/client_profile?` +
      `patient_id=${patientId}&roster_id=${rosterId}&viewing=${viewing}&page=${pageNum}&cb=${Date.now()}`;

    if(forceRefresh) {
      url += `&force_refresh=true`;
    }

    let response = await axios.get(url, {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      },
    });

    let data: ClientProfileResponse = response.data;

    return data;
  }

  async getDischargeSummary(patientId: string, encounterId: string, rosterId: number, contentType: string) {
    let url = `${this.baseUrl}/client_profile/discharge_summary`
      + `?patient_id=${patientId}&roster_id=${rosterId}&encounter_id=${encounterId}&content_type=${contentType}`;

    let response = await axios.get(url, {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      },
    });

    let data: string = response.data;

    return data;
  }

  async getOrganizations() {
    let response = await axios.get(this.baseUrl + "/organizations", {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      },
    });

    let data: Organization[] = response.data;

    return data;
  }

  async putUser(userId: string, userData: UserRequest) {
    let url = this.baseUrl + `/users/${userId}`;
    return axios.put(encodeURI(url),
      userData,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async patchUser(userId: string, userPatchData: UserPatchRequest) {
    const url = `${this.baseUrl}/users/${userId}`;
    const response = await axios.patch(encodeURI(url),
      userPatchData,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`,
        },
      },
    );

    const data: User = response.data;

    return data;
  }

  async putUserProfile(userId: string, userProfileData: UserProfileRequest) {
    let url = this.baseUrl + `/users/${userId}/profile`;
    return axios.put(encodeURI(url),
      userProfileData,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async getUsers() {
    let response = await axios.get(this.baseUrl + "/users", {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      },
    });

    let data: User[] = response.data;

    return data;
  }

  async getUsersAssignedToClients(rosterId: number) {
    const response = await axios.get(`${this.baseUrl}/users/client_assigned?roster_id=${rosterId}`, {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      },
    });

    const data: User[] = response.data;

    return data;
  }

  async getUser(userId: string) {
    let url = this.baseUrl + `/users/${userId}`;
    let response = await axios.get(encodeURI(url), {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      },
    });

    let data: User = response.data;

    return data;
  }

  async postCreateUser(createUserRequest: UserRequest) {
    const response = await axios.post(this.baseUrl + "/users", createUserRequest, {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      },
    });

    const data: {id: string} = response.data;
    return data;
  }

  async login() {
    const response = await axios.get(`${this.baseUrl}/login`, {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      },
    });

    const data: LoginResponse = response.data;

    return data;
  }

  async getLoginPrompts() {
    const response = await axios.get(`${this.baseUrl}/attestation/login_prompts`, {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      },
    });

    const data: LoginResponse = response.data;

    return data;
  }

  async postAttestation(version: string) {
    return axios.post(this.baseUrl + "/attestation", {
      'document_version': version
    }, {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async getBackendVersions(): Promise<BackendVersions> {
    let response = await axios.get(this.baseUrl + "/version");
    return response.data;
  }

  async getRosterDetails(rosterId: string | null) {
    if (rosterId) {
      let response = await axios.get(this.baseUrl + "/roster_details/" + rosterId, {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      });

      let data: RosterPermissions = response.data.rosterPermissions;
      return data;
    }
  }

  async getNotificationSetting() {
    let url = this.baseUrl + `/notification_settings`;
    let response = await axios.get(encodeURI(url), {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      },
    });

    let data: NotificationSettingsDto = response.data;
    return NotificationHelpers.buildNotificationSettings(data);
  }

  async putNotificationSetting(notificationSettingsResponse: NotificationSettingsDto) {
    let url = this.baseUrl + `/notification_settings`;

    return axios.put(encodeURI(url),
      notificationSettingsResponse,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async postClientUserAssignment(patientId: string, userId: string, rosterId: number) {
    return await axios.post(`${this.baseUrl}/clients/user_assignment`, {
      patient_id: patientId,
      user_id: userId,
      roster_id: rosterId
    }, {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async deleteClientUserAssignment(patientId: string, userId: string, rosterId: number) {
    const url = `${this.baseUrl}/clients/user_assignment?patient_id=${patientId}&user_id=${userId}&roster_id=${rosterId}`;

    return await axios.delete(encodeURI(url), {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async searchPatientByName(client: ClientGroup){
    let response = await axios.post(this.baseUrl + "/clients/search", client,{
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      }
    });

    let data: EncounterClient[] = await response.data;
    return data;
  }

  async postFormUpload(form: FormData) {
    let url = `${this.baseUrl}/client_profile/forms/upload`;

    const response = await axios.post(url,
      form,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`,
          "Content-Type": "multipart/form-data"
        }
      }
    );

    const data : {id: number} = response.data;
    return data;
  }

  async getFormUpload(rosterId: number, formRosterId: number, patientId: string, formId: number) {
    let url = `${this.baseUrl}/client_profile/forms/upload`
      + `?roster_id=${rosterId}&form_patient_id=${patientId}&form_roster_id=${formRosterId}&form_id=${formId}`;

    let response = await axios.get(url, {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      }
    });

    return response.data;
  }

  async deleteForm(formId: number) {
    const url = `${this.baseUrl}/client_profile/forms/${formId}`;

    return await axios.delete(encodeURI(url), {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async getForms(rosterId: number, patientId: string) {
    let url = `${this.baseUrl}/client_profile/forms`
      + `?patient_id=${patientId}&roster_id=${rosterId}`;

    let response = await axios.get(url, {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      }
    });

    let data: Form[] = response.data;

    return data;
  }

  async redirectToOpenBedsSSO() {
    let response = await axios.post(this.baseUrl + "/sso/open_beds/redirect",
      {},
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );

    let data: string = response.data;
    return data;
  }

  async getFindHelpUrl(rosterId: number, patientId: string) {
    const url = `${this.baseUrl}/sso/find_help/url?roster_id=${rosterId}&patient_id=${patientId}`;

    const response = await axios.get(encodeURI(url),
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`,
        },
      },
    );

    const data: string = response.data.url;

    return data;
  }

  async readSettings(groupId: number) {
    let url = `${this.baseUrl}/encounters_resources/read_settings?group_id=${groupId}`;

    let response = await axios.get(url, {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      }
    });

    let data: string[] = response.data;

    return data.sort().map((setting) => ({
      value: setting,
      label: DisplayHelpers.humanize(setting),
      id: setting.replace(' ', '-').toLowerCase(),
    }));
  }

  async readDischargeDispositions() {
    let url = `${this.baseUrl}/encounters_resources/read_discharge_dispositions`;

    let response = await axios.get(url, {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      }
    });

    let data: DischargeDisposition[] = response.data;

    return data.map((disposition) => ({
      ...disposition,
      value: disposition.dispositionSynonym,
      label: disposition.dispositionSynonym,
    }))
      .sort((a, b) => {
        if (a.dispositionSynonym < b.dispositionSynonym) return -1;
        if (a.dispositionSynonym > b.dispositionSynonym) return 1;
        return 0;
      });
  }

  async searchFacilities(name: string, facilityDispositionType: string, groupId: number) {
    let url = `${this.baseUrl}/encounters_resources/search_facilities?name=${name}`;

    if (facilityDispositionType) {
      url += `&facility_disposition_type=${facilityDispositionType}`;
    }

    if (groupId) {
      url += `&group_id=${groupId}`;
    }

    let response = await axios.get(encodeURI(url), {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      }
    });

    let data: SearchFacility[] = response.data;

    const facilities = data.map((facility) => ({
      id: facility.id,
      label: facility.name,
    }));
    return facilities.length ? [{ label: 'Home', id: null }, ...facilities] : [];
  }

  async readInsurers() {
    let url = `${this.baseUrl}/encounters_resources/read_insurers`;

    let response = await axios.get(url, {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      }
    });

    let data: Insurance[] = response.data;

    const insurers = data.map((insurance) => {
      const insuranceVar = { ...insurance };
      const insuranceId = insuranceVar.insurerId ? insuranceVar.insurerId : insuranceVar.code;
      const insuranceCompanyName = insuranceVar.companyName;
      // @ts-ignore
      delete insuranceVar.companyName;

      return {
        ...insuranceVar,
        id: insuranceId,
        value: insuranceId,
        label: insuranceCompanyName,
        insuranceCompanyName,
      };
    });
    insurers.push(
      // @ts-ignore
      ...[
        {
          code: 'OTHER',
          insuranceCompanyName: 'Other',
          label: 'Other',
          insurancePlanId: 0,
          insurerId: 0,
          value: '',
        },
        {
          code: 'SELF-PAY',
          insuranceCompanyName: 'Self Pay',
          label: 'Self Pay',
          insurancePlanId: 0,
          insurerId: 0,
          value: '',
        },
      ],
    );
    return insurers.sort((a, b) => {
      if (a.insuranceCompanyName < b.insuranceCompanyName) return -1;
      if (a.insuranceCompanyName > b.insuranceCompanyName) return 1;
      return 0;
    });
  }

  async getNotifications(rosterId: number, unreadOnly: boolean, page: number) {
    let url = `${this.baseUrl}/notifications?roster_id=${rosterId}&page=${page}&unread_only=${unreadOnly}`;

    let response = await axios.get(encodeURI(url), {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      },
    });

    let data: NotificationResponse = response.data;

    return {
      id: page,
      data,
      nextPage: page + 1
    };
  }

  async putNotificationViewed(notificationId: number, rosterId:number, viewed: boolean) {
    let url = this.baseUrl + `/notifications/${notificationId}/rosters/${rosterId}`;
    return axios.put(encodeURI(url),
      {viewed: viewed},
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async putAllNotificationsAsViewed(rosterId: number) {
    let url = this.baseUrl + `/notifications/rosters/${rosterId}/viewed`;
    return axios.put(encodeURI(url),
      {},
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async getPatientRequest(rosterId: number, patientId: string) {
    let url = `${this.baseUrl}/pdmp/patient_request?roster_id=${rosterId}&patient_id=${patientId}`;

    let response = await axios.get(encodeURI(url),
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );

    let data: PatientResponse = response.data;
    return data;
  }

  async getReportRequest(rosterId: number, patientId: string, reportRequestUrl: string) {
    let url = `${this.baseUrl}/pdmp/report_request`;
    url += `?roster_id=${rosterId}&patient_id=${patientId}&report_request_url=${reportRequestUrl}`;

    let response = await axios.get(encodeURI(url),
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );

    return this.baseUrl + "/pdmp/report?report_id=" + response.data;
  }

  async getWalkMeData(user_id: string) {
    let url = `${this.baseUrl}/walk-me/${user_id}`;
    let response = await axios.get(encodeURI(url), {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      }
    });

    let data : WalkMeData = response.data;
    return data;
  }

  async postScanRosterTool(rosterId: number) {
    const url = `${this.baseUrl}/tools/scan_roster/${rosterId}`;
    let response = await axios.post(
      encodeURI(url),
      null,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      });

    let data : ScanRosterResponse = response.data;
    return data;
  }

  async getScanRosterTool() {
    const url = `${this.baseUrl}/tools/scan_roster`;
    let response = await axios.get(
      encodeURI(url),
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      });

    let data : ScanRosterResponse = response.data;
    return data;
  }

  async postMixpanelEvent(event: MixpanelEvent) {
    let url = `${this.baseUrl}/mixpanel`;
    await axios.post(encodeURI(url),
      event, {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      });
  }

  async getAdminDetails(){
    let url = this.baseUrl + `/users/admin_details`;
    let response = await axios.get(encodeURI(url), {
      headers: {
        "Authorization": `Bearer ${await this.getAccessToken()}`
      }
    });
    let data : AdminDetailByRoster[] = response.data;
    return data;
  }
}

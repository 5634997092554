import React, {useContext, useEffect, useState} from 'react';
import OrganizationsTab from "./OrganizationsTab";
import GroupsTab from "./GroupsTab";
import UsersTab from "./UsersTab";
import TabsComponent from "../../common/TabsComponent";
import Wrapper from '../../common/pages/Wrapper';
import Header from '../../common/pages/Header';
import {AdminPageTab, MixpanelAdminPageEvent, MixpanelEventType} from "../../../api/dto/mixpanel";
import {CarecoApiContext} from "../../../app-context/careco-api-context";
import {useAuthStore} from "../../../store/auth_store";
import Helpers from "../../../helpers/helpers";
import {getMixpanelEventProperties} from "../../../helpers/mixpanel_helpers";

const AdminPage = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const {carecoApi} = useContext(CarecoApiContext);
  const {currentUser} = useAuthStore();

  const tabs = [
    {
      value: 0,
      label: 'Users',
      content: <UsersTab />
    },
    {
      value: 1,
      label: 'Groups',
      content: <GroupsTab />
    },
    {
      value: 2,
      label: 'Organizations',
      content: <OrganizationsTab />
    }
  ];

  useEffect(() => {
    if(currentUser && carecoApi && Number.isInteger(currentTab)) {
      const tabs = Object.values(AdminPageTab);
      const adminAccess = currentUser?.role === Helpers.getAppRoles().USER ? "Feature Role" :
        currentUser?.role === Helpers.getAppRoles().SUPER_ADMIN ? "Super Admin" : "Bamboo Admin";

      const event: MixpanelAdminPageEvent = {
        ...getMixpanelEventProperties(MixpanelEventType.BIH_ADMIN_PAGE_VIEWED),
        tabViewed: tabs[currentTab],
        adminAccess
      };

      carecoApi?.postMixpanelEvent(event);
    }
  }, [currentTab, currentUser, carecoApi]);

  return (
    <Wrapper>
      <Header>Admin</Header>
      <div id="remedySlideInPane"></div>
      <TabsComponent tabs={tabs} forceRender={true} setCurrentTab={(currentTab) => setCurrentTab(currentTab)}/>
    </Wrapper>
  );
};

export default AdminPage;

import {ReferralDetails} from "../api/dto/referral";
import {
  AcceptedStatuses,
  ReferralStatus,
  ReferralStatuses, ReferralStatusType
} from "../components/pages/clients/profile_page/summary_tab/timeline_components/ReferralCard.constants";

const ReferralHelpers = {
  getSortedReferrals(referrals: ReferralDetails[]) {
    return referrals?.sort((a: ReferralDetails, b: ReferralDetails) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    }) ?? [];
  },
  isEncounterActive(
    overallStatus: ReferralStatusType
  ) {
    const isNotClosed = overallStatus?.displayName !== ReferralStatus['Closed'].displayName;
    const isNotShowed = overallStatus?.displayName !== ReferralStatus['Show'].displayName;
    const isNotNoShowed = overallStatus?.displayName !== ReferralStatus['No-Show'].displayName;
    const isNotDeclined = overallStatus?.displayName !== ReferralStatus['Declined'].displayName;

    return isNotClosed && isNotShowed && isNotNoShowed && isNotDeclined;
  },
  getActiveReferrals(
    sortedReferrals: ReferralDetails[],
    acceptedReferral: ReferralDetails | undefined
  ) {
    if(!acceptedReferral) {
      return sortedReferrals.filter((referral: ReferralDetails) => {
        return referral.status === ReferralStatuses.Opened &&
            (referral.accepted_status == null
                || referral.accepted_status === AcceptedStatuses.Opened
                || referral.accepted_status === AcceptedStatuses.Accepted);
      });
    } else {
      return [acceptedReferral];
    }
  },
  getInactiveReferrals(
    sortedReferrals: ReferralDetails[],
    acceptedReferral: ReferralDetails | undefined
  ) {
    if(!acceptedReferral) {
      return sortedReferrals.filter((referral: ReferralDetails) => {
        return referral.status === ReferralStatuses.Closed || (
          referral.status === ReferralStatuses.Opened && (
            referral.accepted_status === AcceptedStatuses.Declined
                || referral.accepted_status === AcceptedStatuses.Closed
          )
        );
      });
    } else {
      return sortedReferrals.filter((referral: ReferralDetails) => {
        return referral.id !== acceptedReferral.id;
      }).map((referral: ReferralDetails) => {
        return {
          ...referral,
          accepted_status: AcceptedStatuses.AcceptedElsewhere
        };
      });
    }
  },
  getAcceptedReferral(sortedReferrals: ReferralDetails[]) {
    return sortedReferrals.find((referral: ReferralDetails) => {
      return referral.accepted_status === AcceptedStatuses.Accepted;
    });
  },
  getDeclinedReferrals(inactiveReferrals: ReferralDetails[]) {
    return inactiveReferrals.filter((referral: ReferralDetails) => {
      return referral.accepted_status === AcceptedStatuses.Declined;
    });
  },
  getClosedReferrals(inactiveReferrals: ReferralDetails[]) {
    return inactiveReferrals.filter((referral: ReferralDetails) => {
      return referral.status === ReferralStatuses.Closed || referral.accepted_status === AcceptedStatuses.Closed;
    });
  },
  getOverallEncounterStatus(
    sortedReferrals: ReferralDetails[],
    declinedReferrals: ReferralDetails[],
    closedReferrals: ReferralDetails[],
    acceptedReferral: ReferralDetails | undefined
  ) {
    if (closedReferrals.length === sortedReferrals.length) {
      return ReferralStatus.Closed;
    }

    if (declinedReferrals.length === sortedReferrals.length) {
      return ReferralStatus.Declined;
    }

    if(!acceptedReferral) { // pending status, no one has accepted yet
      return ReferralStatus.Opened;
    }

    if(acceptedReferral) {
      let referralStatus = ReferralStatus[acceptedReferral.status];
      const acceptedStatus = acceptedReferral.accepted_status ? ReferralStatus[acceptedReferral.accepted_status] : null;
      const showStatus = acceptedReferral.show_status ? ReferralStatus[acceptedReferral.show_status] : null;

      if(acceptedStatus) {
        referralStatus = acceptedStatus;
      }

      if(showStatus) {
        referralStatus = showStatus;
      }

      if(acceptedReferral.status === ReferralStatuses.Closed) {
        referralStatus = ReferralStatus.Closed;
      }

      return referralStatus;
    }

    return ReferralStatus.Unknown;
  },
  getTimeByStatus(referral : ReferralDetails, status: string){
    return  referral.reporting_logs
      ?.filter((log) => log.status === status)
      .at(-1)?.status_time || null;
  },
};

export default ReferralHelpers;

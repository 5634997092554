import {ADTEvent, ADTItem, ClientProfile, DischargeEvent} from "../../../../../../api/dto/client-profile";
import React, {useMemo} from "react";
import DisplayHelpers from "../../../../../../helpers/display_helpers";
import LOSIndicator from "../../../../../common/timeline/LOSIndicator";
import ExpandMoreButton from "../../../../../common/timeline/ExpandMoreButton";
import {
  EventContainer,
  EventContainerEvents,
  EventCurrentStatus,
  EventDetails,
  EventLowerSection1,
  EventLowerSection1Content,
  EventLowerSection2,
  EventSectionHeader,
  EventSectionLink, EventSectionRow,
  EventType,
  TimelineContentWrapper,
  TimelineDate,
  TimelineEventWrapper
} from "../../../../../common/timeline/Timeline.styles";
import Helpers from "../../../../../../helpers/helpers";
import DetailsSection from "../../../../../common/timeline/DetailsSection";
import MainEventIcon from "../../../../../common/timeline/MainEventIcon";
import EventsTimeline from "./EventsTimeline";
import EncounterDischargeInfo from "./EncounterDischargeInfo";
import {useRosterStore} from "../../../../../../store/roster_store";
import EncounterActions from "../../../manual_encounter/EncounterActions";
import PermissionsHelpers from "../../../../../../helpers/permissions_helpers";
import {useAuthStore} from "../../../../../../store/auth_store";
import {GroupTypes, Status} from "./ADTCard.constants";
import {GroupType} from "../../../../../../api/dto/dto";
import Icon, {ICONS} from "patient-ping-remedy/packages/icon";
import Spacer from "patient-ping-remedy/packages/spacer";
import {sizes} from "patient-ping-remedy/packages/theme";
import Part2Disclaimer from "../../../../../common/Part2Disclaimer";
import {StyleType} from "patient-ping-remedy/packages/button";

type Props = {
  id: string;
  event: ADTItem,
  isPart2?: boolean,
  patient: ClientProfile,
  forceRefresh: Function,
  latestEncounter?: boolean,
  openDemographics: Function
}

const ADTCard = (props: Props) => {
  const { currentUser } = useAuthStore();
  const eventYear = useMemo(() => new Date(props.event.events.at(-1)!.eventDate).getFullYear(),[props.event.events]);
  const eventDate = useMemo(() =>
    DisplayHelpers.getDateMonthDay(DisplayHelpers.formatDateUTC(props.event.events.at(-1)!.eventDate)), [props.event.events]);
  const eventType = useMemo(() => GroupTypes[props.event.admittingFacility?.type ?? 'Unknown'], [props.event.admittingFacility]);
  const { currentRoster } = useRosterStore();

  const dischargeEvent : DischargeEvent = useMemo(() => {
    let discharge = props.event.events.find((event: ADTEvent) => event.status === Status['Discharged'].displayName);

    return {
      status: discharge?.status ?? '',
      facility: {
        name: props.event.admittingFacility?.name || ''
      },
      eventDate: discharge?.eventDate || ''
    };
  }, [props.event]);

  const sortedEventEvents = useMemo(() => {
    // returned from pings in ascending order
    return [...props.event.events].reverse().filter((event: ADTEvent) => {
      // check that all events have a status and should be displayed
      // if no status or not displayed do not include them in the list
      if (event.status) {
        let status = Status[event.status];
        return status && status.display;
      }

      return false;
    });
  }, [props.event.events]);

  const latestEvent = useMemo(() => {
    let latestEvent = sortedEventEvents.at(0);

    if (latestEvent?.status === Status['Payor Change'].displayName) {
      latestEvent = sortedEventEvents.find((event: ADTEvent) => event.status !== Status['Payor Change'].displayName);
    }

    return latestEvent;

  }, [sortedEventEvents]);

  const active = props.event.isOpen ?? true;

  const latestEncounterInsurance = useMemo(() => {
    for(const event of sortedEventEvents){
      const insuranceCompanyName = event.payor?.insuranceCompanyName;
      if(Helpers.isDefined(insuranceCompanyName) && !Helpers.isEmpty(insuranceCompanyName!)){
        return insuranceCompanyName;
      }
    }
  }, [sortedEventEvents]);

  function scrollToInsurances() {
    const clientDemographics = document.getElementById('client-demographics');
    if (clientDemographics) {
      props.openDemographics();
      clientDemographics.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const calculatedLOS = useMemo(() => {
    if (!active) {
      const dischargeDate = new Date(props.event.events.at(-1)!.eventDate);
      const admitDate = new Date(props.event.events.at(0)!.eventDate);
      return Helpers.calculateTimeDifference(admitDate, dischargeDate);
    } else {
      const today = new Date();
      const admitDate = new Date(props.event.events.at(0)!.eventDate);
      return Helpers.calculateTimeDifference(admitDate, today);
    }
  }, [props.event.events, active]);

  return (
    <div id={props.id}>
      <TimelineEventWrapper>
        <TimelineDate>
          <span className="timeline__event__date-year">{ eventYear }</span>
          <span className="timeline__event__date-date">{ eventDate }</span>
        </TimelineDate>

        <TimelineContentWrapper>
          <EventContainer active={active}>
            <MainEventIcon active={active} iconClass={eventType.iconClass}/>
            <EventDetails>
              <EventType> { eventType.displayName} </EventType>
              <DetailsSection detailsHeaderText={'Treating facility'}
                hasDetails={Helpers.isDefined(props.event.admittingFacility)}>
                <>
                  <div>{props.event.admittingFacility?.name}</div>
                  <div>{props.event.admittingFacility?.address?.address1}</div>
                  {
                    props.event.admittingFacility?.address?.address2 &&
                    <div>{props.event.admittingFacility?.address?.address2}</div>
                  }
                  {
                    props.event.admittingFacility?.address?.address3 &&
                    <div>{props.event.admittingFacility?.address?.address3}</div>
                  }
                  <div>
                    {props.event.admittingFacility?.address?.city + ', '}
                    {props.event.admittingFacility?.address?.state + ' '}
                    {props.event.admittingFacility?.address?.zip}
                  </div>
                </>
              </DetailsSection>

              <DetailsSection detailsHeaderText={'Attending provider'}
                hasDetails={Helpers.isDefined(props.event.attendingProvider)}>
                <>
                  <div>
                    {DisplayHelpers.displayHonorific(props.event.attendingProvider?.honorific) + ' '}
                    {props.event.attendingProvider?.firstName + ' '}
                    {props.event.attendingProvider?.lastName}
                  </div>
                  <div>{props.event.attendingProvider?.email}</div>
                  <div>{props.event.attendingProvider?.phone}</div>
                </>
              </DetailsSection>
            </EventDetails>

            <EventContainerEvents active={active}>
              <EventCurrentStatus>
                {
                  latestEvent &&
                  <LOSIndicator
                    message={calculatedLOS > 1 ? `LOS ${calculatedLOS} days:` : `LOS ${calculatedLOS} day:`}
                    iconClass={Status[latestEvent.status ?? 'Unknown']?.iconClass}
                    active={active}
                    iconMessage={!active || !latestEvent.setting
                      ? latestEvent?.status || ''
                      : latestEvent?.setting || ''}
                  />
                }
                {
                  PermissionsHelpers.canForUserDataRoleRoster(
                    currentUser!,
                    currentRoster!,
                    Helpers.getAppDataRoleDetails().levels.WRITE,
                    Helpers.getAppDataRoleDetails().permissions.ADT
                  ) && currentRoster?.admittingFacility
                    && !currentRoster.automated
                    && props.event.admittingFacility?.groupId === currentRoster.id
                    && currentRoster.type !== GroupType.CRISIS &&
                    props.event.admittingFacilityPublicPatientId === props.patient.patient.patientId &&
                  <EncounterActions
                    canEdit={props.latestEncounter}
                    patient={props.patient}
                    event={props.event}
                    forceRefresh={props.forceRefresh} />
                }
              </EventCurrentStatus>

              <EventsTimeline active={active}
                events={sortedEventEvents}
                facilityName={props.event.admittingFacility?.name || ''}
                dischargeDisposition={props.event.dischargeDisposition || ''}
                isManual = {props.event.isManual}
              />

              <EventLowerSection1 inline>
                {props.event.diagnoses.length > 0 &&
                  <EventSectionRow>
                    <EventSectionHeader>Diagnoses:</EventSectionHeader>
                    <EventLowerSection1Content>
                      {
                        props.event.diagnoses.slice(0,3).map((diagnosis, index) => (
                          <div className="details" key={`visible-diag-${index}`}>
                            {diagnosis.synonym} {diagnosis.fullCode ? `(${diagnosis.fullCode})` : ''}
                          </div>
                        ))
                      }

                      {props.event.diagnoses.length > 3 &&
                        <ExpandMoreButton
                          useExpandHideText={true}
                          active={active}
                          buttonText={`${props.event.diagnoses.length - 3} more`}
                          expandItems={props.event.diagnoses.slice(3).map((diagnosis, index) => (
                            <div className="details" key={`hidden-diag-${index}`}>
                              {diagnosis.synonym} ({diagnosis.fullCode})
                            </div>
                          ))
                          }
                        />
                      }
                    </EventLowerSection1Content>
                  </EventSectionRow>
                }
                <EventSectionRow>
                  <EventSectionHeader>Insurance:</EventSectionHeader>
                  <EventLowerSection1Content>
                    {
                      <div className="details" key={`insurance-${latestEncounterInsurance}`}>
                        {latestEncounterInsurance || "None Provided"}
                        <EventSectionLink onClick={scrollToInsurances}>
                          <Icon iconClass={ICONS.history} />
                          <Spacer itemWidth={sizes.xxSmall} />
                          View history
                        </EventSectionLink>
                      </div>
                    }
                  </EventLowerSection1Content>
                </EventSectionRow>
              </EventLowerSection1>

              {
                props.event.dischargeSummaryInfo &&
              <EventLowerSection2>
                <EventSectionHeader>Documents:</EventSectionHeader>
                <EncounterDischargeInfo
                  event={dischargeEvent}
                  selectedRoster={currentRoster?.id!}
                  dischargeInfo={{
                    ...props.event.dischargeSummaryInfo,
                    encounterId: props.event.encounterId!
                  }}
                  patient={props.patient.patient} />
              </EventLowerSection2>
              }
            </EventContainerEvents>
            { props.isPart2 && <Part2Disclaimer styleType={active ? StyleType.PRIMARY : StyleType.TERTIARY} /> }
          </EventContainer>
        </TimelineContentWrapper>
      </TimelineEventWrapper>
    </div>
  );
};

export default ADTCard;

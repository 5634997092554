import {
  EventContainerEventsList,
  EventContainerEventsSession,
  EventContainerEventsStatus,
  EventContainerEventsTime,
  EventContainerEventsWrapper
} from "../../../../../common/timeline/Timeline.styles";
import DisplayHelpers from "../../../../../../helpers/display_helpers";
import React, {useMemo} from "react";
import {ReferralDetails} from "../../../../../../api/dto/referral";
import {
  AcceptedStatuses,
  ReferralStatus,
  ReferralStatuses,
  ReferralStatusType,
  ReportingLogStatus,
} from "./ReferralCard.constants";
import ReferralHelpers from "../../../../../../helpers/referral_helpers";

type Props = {
  referrals: ReferralDetails[],
  acceptedReferral: ReferralDetails | undefined,
  active: boolean,
  overallCardStatus: ReferralStatusType
}

type ReferralTimelineEvent = {
  id: string;
  eventTime: Nullable<string>;
  referral: ReferralDetails;
  status: ReferralStatusType;
}

const ReferralTimeline = (props: Props) => {
  const referralEventsBackfilled = useMemo(() => {
    if(!props.acceptedReferral) {
      // this means there are many active referrals none of which have been accepted
      // we will show the referral being created and information about referral
      // accepted status etc will be in the details section

      let earliestReferral = props.referrals.at(0)!;
      let referralEvents : ReferralTimelineEvent[] = [
        {
          id: `referral-multiple-created`,
          eventTime: earliestReferral.created_at,
          referral: earliestReferral,
          status: ReferralStatus.Opened
        }
      ];

      // if there are any declined referrals, add them to the timeline
      props.referrals.filter((referral) => {
        return referral.accepted_status === AcceptedStatuses.Declined;
      }).forEach((referral) => {
        referralEvents.push({
          id: `referral-declined-${referral.id}`,
          eventTime: ReferralHelpers.getTimeByStatus(referral, ReportingLogStatus.DECLINED),
          referral: referral,
          status: ReferralStatus.Declined
        });
      });

      if(props.overallCardStatus === ReferralStatus.Closed) {
        referralEvents.push({
          id: `referral-closed`,
          eventTime: ReferralHelpers.getTimeByStatus(earliestReferral, ReportingLogStatus.CLOSED),
          referral: earliestReferral,
          status: ReferralStatus.Closed
        });
      }

      return referralEvents.reverse();
    } else {
      // there is only one accepted referral, backfill show and accepted events to be displayed
      let referralWithBackfilledEvents : ReferralTimelineEvent[] = [];
      let referral = props.acceptedReferral;
      let acceptedStatus = referral.accepted_status ? ReferralStatus[referral.accepted_status] : null;
      let showStatus = referral.show_status ? ReferralStatus[referral.show_status] : null;

      // each referral has to have an initial created event
      referralWithBackfilledEvents.push({
        id: `referral-${referral.id}`,
        eventTime: referral.created_at,
        referral: referral,
        status: ReferralStatus.Opened
      });

      if(acceptedStatus) {
        referralWithBackfilledEvents.push({
          id: `referral-accepted-${referral.id}`,
          eventTime: ReferralHelpers.getTimeByStatus(referral, ReportingLogStatus.ACCEPTED),
          referral: referral,
          status: acceptedStatus
        });
      }

      if(showStatus) {
        referralWithBackfilledEvents.push({
          id: `referral-show-${referral.id}`,
          eventTime: ReferralHelpers.getTimeByStatus(referral, referral.show_status!),
          referral: referral,
          status: showStatus
        });
      }

      if(referral.status === ReferralStatuses.Closed) {
        referralWithBackfilledEvents.push({
          id: `referral-closed-${referral.id}`,
          eventTime: ReferralHelpers.getTimeByStatus(referral, ReportingLogStatus.CLOSED),
          referral: referral,
          status: ReferralStatus.Closed
        });
      }

      return referralWithBackfilledEvents.reverse();
    }
  }, [props.referrals]);

  return (
    <EventContainerEventsWrapper>
      <EventContainerEventsSession>
        {
          referralEventsBackfilled.map((referralTimelineEvent, index) => {
            return (
              <EventContainerEventsList data-testid={referralTimelineEvent.id} active={props.active} key={index}>
                <EventContainerEventsTime>
                  {
                    referralTimelineEvent.eventTime ?
                      <>
                        {DisplayHelpers.formatDate(referralTimelineEvent.eventTime)}
                        <span className="pipe-separator">{DisplayHelpers.formatTime(referralTimelineEvent.eventTime)}</span>
                      </>
                      : <>Date not available</>
                  }
                </EventContainerEventsTime>

                <EventContainerEventsStatus>
                  {
                    referralTimelineEvent.status &&
                      referralTimelineEvent.status.displayMessage(referralTimelineEvent.referral)
                  }
                </EventContainerEventsStatus>
              </EventContainerEventsList>
            );
          })
        }
      </EventContainerEventsSession>
    </EventContainerEventsWrapper>
  );
};

export default ReferralTimeline;

import styled from '@emotion/styled';


export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
  margin: 20px;
`;

export const UserDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.5rem;
`;

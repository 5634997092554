import Modal from "patient-ping-remedy/packages/modal";
import Button, {StyleType} from "patient-ping-remedy/packages/button";
import React, {useContext, useEffect, useState} from "react";
import {StyledSearchRow, StyledSmallTypography} from "./AdmitClientModal.styles";
import PatientInformationForm from "./PatientInformationForm";
import {createFormFieldConfig, useForm} from "../../../../../hooks/useForm";
import Validate from "../../../../../helpers/validate";
import Spacer from "patient-ping-remedy/packages/spacer";
import {sizes} from "patient-ping-remedy/packages/theme";
import ChooseAdmitClient from "./ChooseAdmitClient";
import { ClientGroup} from "../../../../../api/dto/dto";
import {CarecoApiContext} from "../../../../../app-context/careco-api-context";
import {useMutation} from "@tanstack/react-query";
import Helpers from "../../../../../helpers/helpers";
import {EncounterClient} from "../../../../../api/dto/encounter";
import {AxiosError} from "axios";
import {GENERICERROR} from "../constants/BannerMessages";
import {ToastType} from "patient-ping-remedy/packages/toast";
import {useAlertStore} from "../../../../../store/alert_store";

interface AdmitClientSearchModalProps {
  hideModal: () => void,
  rosterId?: number,
  setSelectedPatient: (patient: EncounterClient | undefined) => void;
}

const AdmitClientModal = (props: AdmitClientSearchModalProps) => {
  const [matchedClients, setMatchedClients] = useState<EncounterClient[]>([]);
  const {handleChange, form, setInitialForm, isValid} = useForm(handleSearch);
  const { carecoApi } = useContext(CarecoApiContext);

  const {addAlert} = useAlertStore();

  const {mutateAsync, isPending} = useMutation({
    mutationFn: async (client: ClientGroup) => carecoApi?.searchPatientByName(client),
    onError: (error: AxiosError) => {
      const traceId = error.response?.headers['x-trace-id'];
      addAlert({
        content: `${GENERICERROR} ${Helpers.traceId(traceId)}`,
        type: ToastType.ERROR
      });
      console.error(error);
    }
  });

  useEffect(() => {
    setInitialForm({
      ...createFormFieldConfig(
        'first_name',
        '',
        { valid: false, message: '' },
        [Validate.required, Validate.firstName],
        true
      ),
      ...createFormFieldConfig(
        'last_name',
        '',
        { valid: false, message: '' },
        [Validate.required, Validate.lastName],
        true
      ),
      ...createFormFieldConfig(
        'choosePatient',
        '',
        { valid: true, message: '' },
        [],
        true
      )
    });
  }, [setInitialForm]);

  async function handleSearch() {
    const payload = {
      firstName: form.first_name?.value,
      lastName: form.last_name?.value,
      admittingFacilityId: props.rosterId
    };
    const newPatient: EncounterClient = {
      publicPatientId:"",
      patientId: "NEW",
      firstName: payload.firstName,
      lastName: payload.lastName,
      dateOfBirth: "",
      gender: "",
      middleName: ""
    };

    const clientInformation = await mutateAsync(payload) || [];
    clientInformation.push(newPatient);
    setMatchedClients(clientInformation);
  }

  function handleSelect(){
    const patientInformation = matchedClients?.find((client) => client.patientId === form.choosePatient.value);
    props.setSelectedPatient(patientInformation);
  }

  const modalButtons = [
    <Button
      key="Select"
      styleType={StyleType.PRIMARY}
      onClick={handleSelect}
      disabled={!Helpers.isDefined(matchedClients) || Helpers.isEmptyArray(matchedClients!)}
    >
      Select
    </Button>,
    <Button key="Cancel" styleType={StyleType.TERTIARY} onClick={props.hideModal}>
      Cancel
    </Button>,
  ];

  return (
    <Modal headerText="Admit Client" buttons={modalButtons}>
      <StyledSmallTypography>
        All fields marked with <span>*</span> are required.
      </StyledSmallTypography>
      <StyledSmallTypography>
        Enter a client’s information to see if they’ve been admitted to your facility before.
      </StyledSmallTypography>
      <StyledSearchRow>
        <PatientInformationForm
          handleChange={handleChange}
          form={form}
        />
        <Spacer itemWidth={sizes.large} />
        <Button
          styleType={StyleType.SECONDARY}
          onClick={handleSearch}
          disabled={(!isValid()) || isPending}
        >
          Search
        </Button>
      </StyledSearchRow>
      {!Helpers.isEmptyArray(matchedClients) && 
          <ChooseAdmitClient 
            matchedClients={matchedClients}
            handleChange={handleChange}
          />}
    </Modal>
  );
};

export default AdmitClientModal;
